import {useEffect, useState} from 'react';
import {withRouter} from '../../../common/with-router';
import {Link} from 'react-router-dom';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import Pagination from '../../../components/Pagination';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import {adminGetUsers} from '../../../api/user';
import UserListActions from "../../../components/users/UserListActions";
import UserListFilter from "../../../components/users/UserListFilter";

const UserList = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState({});
	const [isSearching, setIsSearching] = useState(false);
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [filteredRole, setFilteredRole] = useState('all');
	const [searchTerm, setSearchTerm] = useState('');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		await fetchUsers();
	}

	const fetchUsers = async () => {
		try {
			const getAllUsers = await adminGetUsers(page, sortDirection, sortedColumn, filteredRole, searchTerm);

			if (getAllUsers.data.status === 'success') {
				setUsers(getAllUsers.data.users);
				setLoading(false);
			}
		} catch (error) {
			console.log('ERROR', error);
		}
	}

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setPage(1);
		setUsers(data);
	};

	useEffect(() => {
		const fetchData = async () => {
			await fetchUsers(1, sortDirection, sortedColumn, filteredRole);
		};
		fetchData();
	}, [filteredRole, sortDirection, sortedColumn]);

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page]);

	if (loading) {
		return <Loading/>;
	}

	const columns = () => {
		return Object.entries(users.data).map(([key, value]) => {
			const userRoles = value?.role && typeof value.role === 'string' && JSON.parse(value.role)
			const formattedUserRoles = userRoles
				.map(role => role.charAt(0).toUpperCase() + role.slice(1))
				.join(", ")

			return {
				view: 'admin',
				id: value.id,
				first_name: value.first_name,
				last_name: value.last_name,
				email: value.email,
				role: formattedUserRoles,
				status: value.blocked ? 'Blocked' : 'Active'
			};
		});
	};

	const refreshUsers = async () => {
		await fetchUsers();
	}

	return (
		<div className="container-fluid">
			<div className="row">
			</div>
			<div className="row">
				<div className="col-12 col-md-3">
					<h1>Users <Link to="/admin/users/edit" className="btn btn-new"><FontAwesomeIcon
						icon={faPlusSquare}/></Link></h1>
				</div>
				<div className="col-12 col-md-9 mb-3">
					<div className="row">
						<div className="col-12 col-md-3">
							<UserListActions
								selectedUsers={selectedUsers}
								onActionCompleted={() => refreshUsers()}
							/>
						</div>
						<div className="col-12 col-md-3">
							<UserListFilter
								onRoleChange={(role) => setFilteredRole(role)}
							/>
						</div>
						<div className="col-12 col-md-6">
							<Search handleSearch={handleSearch}
									endpoint={`admin/users/search`}
									page={page}
									additionalParams={{
										role: filteredRole
									}}
									setIsSearching={setIsSearching}
									placeholder="Search Name or Email"
									setLoading={setLoading}
									onTermChange={(term) => setSearchTerm(term)}
							/>
						</div>
					</div>
				</div>
				<div className="col-12 form-group">
					<div>
						{(users.data) ?
							<div>
								<Table
									data={columns()}
									onSortChange={handleSort}
									visibleColumns={[
										{
											name: 'select',
											type: 'checkbox'
										},
										{
											name: 'first_name',
											sort: 'first_name'
										},
										{
											name: 'last_name',
											sort: 'last_name'
										},
										{
											name: 'email',
											sort: 'email'
										},
										{
											name: 'role',
											sort: 'role'
										},
										{
											name: 'status',
											sort: 'status'
										},
									]}
									onSelectedRowsChange={(userIds) => setSelectedUsers(userIds)}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="email"
									linkLocation="/admin/users/edit"
									editable={true}
								/>
								<Pagination data={users} setPage={setPage}/>
							</div> : <div className="alert alert-danger">No users found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(UserList);
