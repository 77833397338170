import React, { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getAdminEntry } from '../../../api/entries';
import { checkInAthlete } from '../../../api/athletes';
import {dataChangeRequest, editBTCrew} from '../../../api/boattent';
import Modal from "../../../components/global/modal";

const SingleBoatTent = (props) => {
	const entryId = props.router.params.id;

	const [loading, setLoading] = useState(true);
	const [entry, setEntry] = useState({});
	const [message, setMessage] = useState("");
	const [checkIn, setCheckIn] = useState(false);
	const [flushData, setFlushData] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [selectedAthlete, setSelectedAthlete] = useState(null);

	const getInitialData = async () => {
		try {
			const adminEntryRes = await getAdminEntry(entryId);
			if (adminEntryRes.data.status === 'success') {
				setEntry(adminEntryRes.data.entry);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getInitialData();
	}, [flushData]);

	const handleReorder = (currentIndex, newIndex, values, setFieldValue) => {
		const athletesCopy = [...values.athletes];

		let currentAthlete = { ...athletesCopy[currentIndex] };
		let newAthlete = { ...athletesCopy[newIndex] };

		// Swap all values except position
		[currentAthlete, newAthlete] = [newAthlete, currentAthlete];

		// Re-assign the position values to the originals
		currentAthlete.position = athletesCopy[currentIndex].position;
		newAthlete.position = athletesCopy[newIndex].position;

		athletesCopy[currentIndex] = currentAthlete;
		athletesCopy[newIndex] = newAthlete;

		setFieldValue('athletes', athletesCopy);
	};

	const checkUserIn = async (id, status) => {
		let toastId = null;
		if (status === 1) {
			toastId = toast("Checking in athlete...", { autoClose: false, type: 'info' });
		} else {
			toastId = toast("Checking out athlete...", { autoClose: false, type: 'info' });
		}

		try {
			const checkInAthleteById = await checkInAthlete({ athlete_id: id, checked_in: status });
			if (checkInAthleteById.data.status === 'success') {
				if (status === 1) {
					toast.update(toastId, { autoClose: 5000, type: 'success', render: 'Athlete checked in' });
				} else {
					toast.update(toastId, { autoClose: 5000, type: 'success', render: 'Athlete checked out' });
				}
				// increment flush data
				setFlushData(flushData + 1);

			} else {
				toast.update(toastId, { autoClose: 5000, type: 'error', render: 'Error checking in athlete' });
			}
		} catch (error) {
			toast.update(toastId, { autoClose: 5000, type: 'error', render: 'Error checking in athlete' });
		}
	};

	// Data request modal
	const openModal = (data) => {
		setSelectedAthlete(data);
		setShowModal(true);
	}

	const modalBody = () => {
		const initialValues = {
			request: '',
		}

		const validationSchema = Yup.object().shape({
			request: Yup.string().required('Required'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			const toastId = toast("Sending request...", { autoClose: false });

			setSubmitting(true);

			const data = {
				crew_number: entry?.crew?.crew_number,
				first_name: selectedAthlete?.athletes?.first_name,
				last_name: selectedAthlete?.athletes?.last_name,
				dob: selectedAthlete?.athletes?.dob,
				hrr_number: selectedAthlete?.athletes?.hrr_number,
				...values,
			};

			try {
				const dataChangeResponse = await dataChangeRequest(data);
				if (dataChangeResponse.data.status === 'success') {
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Request sent", autoClose: 5000 });
				}
			} catch (error) {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Something went wrong, please try again later", autoClose: 5000 });
				console.log(error);
			}

			setSubmitting(false);
			setShowModal(false);
		}

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ values, setFieldValue, isValid }) => (
					<Form>
						<div>
							<p><b>Crew number:</b> {entry?.crew?.crew_number}</p>
							<p><b>First name:</b> {selectedAthlete?.athletes?.first_name}</p>
							<p><b>Last name:</b> {selectedAthlete?.athletes?.last_name}</p>
							<p><b>Date of birth:</b> {selectedAthlete?.athletes?.dob}</p>
							<p><b>HRR Number:</b> {selectedAthlete?.athletes?.hrr_number}</p>
						</div>
						<label htmlFor="request">Request changes</label>
						<Field type="textarea" className="form-control" name={`request`} required placeholder="Please describe the nature of the change required....."/>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
								Close
							</button>
							<button type="submit" className="btn btn-primary">
								Save changes
							</button>
						</div>
					</Form>
				)}
			</Formik>
		)
	}

	const EventForm = () => {
		const steerIndex = (entry && entry.crew.crew_members?.findIndex(member => member.steer === 1)) ? entry.crew.crew_members?.findIndex(member => member.steer === 1) : null;

		const initialValues = {
			athletes: Array.from({ length: entry.event.event_types.athlete_qty }, (_, index) => {
				//console.log(entry.crew?.crew_members[index]);
				return {
					id: (entry && entry.crew?.crew_members[index]?.athletes && entry.crew?.crew_members[index]?.athletes?.id) ? entry.crew.crew_members[index]?.athletes?.id : '',
					hrr_number: (entry && entry.crew?.crew_members[index]?.athletes && entry.crew?.crew_members[index]?.athletes?.hrr_number) ? entry.crew.crew_members[index]?.athletes?.hrr_number : '',
					position: index === 0 ? 'Bow' :
						index === (entry.event?.event_types?.athlete_qty - 1) && entry.event?.event_types?.cox === 1 ? 'Cox' :
							index === (entry.event?.event_types?.athlete_qty - 2) && entry.event?.event_types?.cox === 1 ? 'Stroke' :
								index === (entry.event?.event_types?.athlete_qty - 1) && entry.event?.event_types?.cox === 0 ? 'Stroke' :
									index + 1,
					steer: (entry && entry.crew?.crew_members[index]?.steer) ? index : 0,
					club: (entry && entry.crew?.crew_members[index]?.club_id) ? entry.crew.crew_members[index]?.club_id : '',
					first_name: (entry && entry.crew?.crew_members[index]?.athletes && entry.crew?.crew_members[index]?.athletes?.first_name) ? entry.crew.crew_members[index]?.athletes?.first_name : 'sddfd',
					last_name: (entry && entry.crew?.crew_members[index]?.athletes && entry.crew?.crew_members[index]?.athletes?.last_name) ? entry.crew.crew_members[index]?.athletes?.last_name : '',
					dob: (entry && entry.crew?.crew_members[index] && entry.crew?.crew_members[index]?.athletes?.dob) ? entry.crew.crew_members?.[index]?.athletes?.dob : '',
					initials: {
						0: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[0]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[0] : '',
						1: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[1]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[1] : '',
						2: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[2]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[2] : '',
					},
					previously_rowed: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.previously_rowed) ? entry.crew.crew_members?.[index]?.athletes?.previously_rowed : 0,
					checkedin: (entry && entry.crew?.crew_members[index]?.athletes?.boat_tent && entry.crew?.crew_members[index]?.athletes?.boat_tent?.checked_in) ? entry.crew.crew_members[index]?.athletes?.boat_tent?.checked_in : 0,
					participations: (entry && entry.crew?.crew_members[index]?.athletes?.participations) ? entry.crew.crew_members[index]?.athletes?.participations : 0,
					history: (entry && entry.crew?.crew_members[index]?.athletes?.historyCondensed) ? entry.crew.crew_members[index]?.athletes?.historyCondensed : '',
				}
			}),
			steer: (entry && steerIndex && steerIndex !== -1) ? steerIndex.toString() : '0',
		};

		const validationSchema = Yup.object().shape({
			athletes: Yup.array()
				.of(
					Yup.object().shape({
						position: Yup.string().required('Required'),
						first_name: Yup.string().required('Required'),
						last_name: Yup.string().required('Required'),
						dob: Yup.string().required('Required'),
					})
				)
				.required('Must have athletes'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			// setLoading(true);
			const toastId = toast("Saving...", { autoClose: false });

			setSubmitting(true);

			const data = {
				...values,
				entry_id: entry.id,
				event_id: entry.id,
				crew_id: entry.id,
			};

			try {
				const adminEntries = await editBTCrew(entryId, data);
				if (adminEntries.data.status === 'success') {
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry saved", autoClose: 5000 });
					return;
				}
			} catch (error) {
				console.log(error);
			}
		};

		const hasChanges = (initialValues, values, index) => {
			return JSON.stringify(values.athletes[index]) !== JSON.stringify(initialValues.athletes[index])
		};

		return (
			<>
				<div className="col-md-12">
					<div className="card card-container">
						<div>
							<table className="table">
								<tbody>
								<tr>
									<td>Crew ID</td>
									<td>{entry.crew?.identifier}</td>
								</tr>
								<tr>
									<td width="150px">Crew Number</td>
									<td>
										{entry.crew?.crew_number}
									</td>
								</tr>
								<tr>
									<td width="150px">Crew Name</td>
									<td>
										{entry.crew?.crew_name}
									</td>
								</tr>
								</tbody>
							</table>
							<button type="button" onClick={() => setCheckIn(true)} className="btn btn-secondary" disabled={checkIn}>Register Athletes</button>

							{(checkIn) && (
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={onSubmit}
								>
									{({ values, setFieldValue, isValid }) => (
										<Form>
											<hr />
											<h3>Crew</h3>
											<div className="table-responsive">
												<table className="table table-striped">
													<thead>
													<tr>
														<th></th>
														<th>HRR</th>
														<th width="100px">Position</th>
														{(entry.event.event_types.steer === 1) && (
															<th>
																Steer
															</th>
														)}
														<th>First Name</th>
														<th width="250px">Initials</th>
														<th>Last Name</th>
														<th>DOB</th>
														<th>Participations</th>
														<th>History</th>
														<th></th>
													</tr>
													</thead>
													<tbody>
													{Array.from({ length: entry.event.event_types.athlete_qty }, (_, index) =>
														<tr key={index}>
															<td>
																<button
																	type="button"
																	onClick={() => handleReorder(index, index - 1, values, setFieldValue)}
																	disabled={index === 0}
																	className="btn btn-reorder"
																><FontAwesomeIcon icon={faArrowUp} /></button>
																<button
																	type="button"
																	onClick={() => handleReorder(index, index + 1, values, setFieldValue)}
																	disabled={index === values.athletes.length - 1}
																	className="btn btn-reorder"
																>
																	<FontAwesomeIcon icon={faArrowDown} />
																</button>
															</td>
															<td>
																{values.athletes?.[index]?.hrr_number}
															</td>
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].position`} disabled />
															</td>
															{(entry.event.event_types.steer === 1) && (
																<td>
																	<Field type="radio" name="steer" value={index} checked={values.steer === index.toString()} />
																</td>
															)}
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].first_name`} required />
															</td>
															<td>
																<div className="d-flex">
																	<div className="px-1">
																		<Field type="text" className="form-control" name={`athletes[${index}].initials[0]`} />
																	</div>
																	{(values.athletes?.[index].initials?.[0]?.length > 0) &&
																		<div className="px-1">
																			<Field type="text" className="form-control" name={`athletes[${index}].initials[1]`} />
																		</div>
																	}
																	{(values.athletes?.[index]?.initials?.[1]?.length > 0) &&
																		<div className="px-1">
																			<Field type="text" className="form-control" name={`athletes[${index}].initials[2]`} />
																		</div>
																	}
																</div>
															</td>
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].last_name`} disabled />
															</td>
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].dob`} disabled />
															</td>
															<td>
																{(values.athletes?.[index]?.participations) ? values.athletes?.[index]?.participations:0}
															</td>
															<td>
																{(values.athletes?.[index]?.history) ? values.athletes?.[index]?.history:''}
															</td>
															<td>
																<button
																	type="button"
																	onClick={() => openModal(entry.crew?.crew_members[index])}
																	className="btn btn-secondary"
																	disabled={hasChanges(initialValues, values, index)}
																>
																	Request data change
																</button>
															</td>
															<td>
																{(values.athletes?.[index]?.checkedin === 1) ?
																	<button type="button" className='btn btn-success' onClick={() => checkUserIn(values.athletes?.[index]?.id, 0)}><FontAwesomeIcon icon={faCheckCircle} /></button>
																	:
																	<button type="button" className='btn btn-primary' onClick={() => checkUserIn(values.athletes?.[index]?.id, 1)}><FontAwesomeIcon icon={faCheckCircle} /></button>
																}
															</td>
														</tr>
													)}
													</tbody>
												</table>
											</div>

											<div className="form-group form-buttons">
												<button
													className="btn btn-primary btn-block"
													type="submit"
													disabled={(loading)}
												>
													{loading && (
														<span className="spinner-border spinner-border-sm"></span>
													)}
													<span>Save</span>
												</button>
											</div>

											{message && (
												<div className="form-group">
													<div className="alert alert-danger" role="alert">
														{message}
													</div>
												</div>
											)}
										</Form>
									)}
								</Formik>
							)}
						</div>
					</div>
				</div>
				<Modal
					title="Athlete data change request"
					onSave={() => setShowModal(false)}
					onClose={() => setShowModal(false)}
					setShow={setShowModal}
					show={showModal}
					children={modalBody()}
				/>
			</>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Entry</h1>
					<div>
						{(entry) && <EventForm />}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(SingleBoatTent);
