import React, {useState, useEffect} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
	faEarthAmericas,
	faGear,
	faUsers,
	faBullhorn,
	faSailboat,
	faCalendarDays,
	faClipboardList,
	faBuildingNgo,
	faFileInvoiceDollar,
	faStopwatch,
	faTable,
	faTicket,
	faTents,
	faUser,
	faFile,
	faSquarePollVertical,
	faHistory,
	faSquarePlus
} from '@fortawesome/free-solid-svg-icons'

const AdminNav = (getRoles) => {
	const checkRole = (role) => { return getRoles.getRoles.includes(role) }
	return (
		<div>
			<hr/>
			<nav>
				{(checkRole('admin') || checkRole('regatta-admin')) &&
					<ul>
						<li>
							<NavLink to='/admin/entries'><span className="menu-icon"><FontAwesomeIcon
								icon={faClipboardList}/></span> <span className="menu-text">Entries</span></NavLink>
						</li>
						<li>
							<NavLink to={"/admin/add-entry"} state={{flush: true}}><span
								className="menu-icon"><FontAwesomeIcon icon={faSquarePlus}/></span> <span
								className="menu-text">Add Entry</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/qualifying-timetable'><span className="menu-icon"><FontAwesomeIcon
								icon={faStopwatch}/></span> <span className="menu-text">Qualifying</span></NavLink>
							<ul className="sub-menu">
								<li>
									<NavLink to='/admin/qualifiers'><span className="menu-text">Qualifying Status</span></NavLink>
								</li>
								<li>
									<NavLink to='/admin/qualifying-groups'><span
										className="menu-text">Qualifying Groups</span></NavLink>
								</li>
								<li>
									<NavLink to='/admin/qualifying-timetable'><span
										className="menu-text">Timetable</span></NavLink>
								</li>
								<li>
									<NavLink to='/admin/qualifying-results'><span
										className="menu-text">Results</span></NavLink>
								</li>
							</ul>
						</li>
						<li>
							<NavLink to='/admin/race-timetable'><span className="menu-icon"><FontAwesomeIcon
								icon={faTable}/></span> <span className="menu-text">Racing Timetable</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/race-results'><span className="menu-icon"><FontAwesomeIcon
								icon={faSquarePollVertical}/></span> <span
								className="menu-text">Race Results</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/athletes'><span className="menu-icon"><FontAwesomeIcon icon={faUsers}/></span>
								<span className="menu-text">Athletes</span></NavLink>
							<ul className="sub-menu">
								<li>
									<NavLink to='/admin/athletes'><span className="menu-text">Athletes</span></NavLink>
								</li>
								<li>
									<NavLink to='/admin/athletes/duplicates'><span
										className="menu-text">Duplicates</span></NavLink>
								</li>
							</ul>
						</li>
						<li>
							<NavLink to='/admin/reports'><span className="menu-icon"><FontAwesomeIcon
								icon={faFile}/></span> <span className="menu-text">Reports</span></NavLink>
						</li>
						{(checkRole('admin') || checkRole('regatta-admin') || checkRole('regatta-finance')) &&
							<li>
								<NavLink to='/admin/orders'><span className="menu-icon"><FontAwesomeIcon
									icon={faFileInvoiceDollar}/></span> <span
									className="menu-text">Orders</span></NavLink>
							</li>
						}
					</ul>
				}

				{(checkRole('press-office')) && (
					<ul>
						<li>
							<NavLink to='/admin/entries'><span className="menu-icon"><FontAwesomeIcon
								icon={faClipboardList}/></span> <span className="menu-text">Entries</span></NavLink>
						</li>
					</ul>
				)}
			</nav>

			<hr/>
			{(checkRole('admin') || checkRole('regatta-admin') || checkRole('boattent') || checkRole('commentator') || checkRole('press-office')) && (
				<>
					<nav>
						<ul>
							{(checkRole('admin') || checkRole('regatta-admin') || checkRole('boattent')) && (
								<li>
									<NavLink to="/admin/boat-tent">
									<span className="menu-icon">
									  <FontAwesomeIcon icon={faTents}/>
									</span>
										<span className="menu-text">Boat Tent Area</span>
									</NavLink>
									<ul className="sub-menu">
										<li>
											<NavLink to="/admin/boat-tent">
												<span className="menu-text">Crew Registrations</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/admin/boat-tent-weigh-in">
												<span className="menu-text">Cox Weigh-In</span>
											</NavLink>
										</li>
									</ul>
								</li>
							)}
							{(checkRole('admin') || checkRole('regatta-admin') || checkRole('commentator') || checkRole('press-office')) && (
								<li>
									<NavLink to="/admin/commentators">
									<span className="menu-icon">
									  <FontAwesomeIcon icon={faBullhorn}/>
									</span>
										<span className="menu-text">Commentators</span>
									</NavLink>
									<ul className="sub-menu">
										<li>
											<NavLink to="/admin/commentators">
												<span className="menu-text">Race List</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/admin/commentators/crews">
												<span className="menu-text">Crew List</span>
											</NavLink>
										</li>
									</ul>
								</li>
							)}
						</ul>
					</nav>
					<hr/>
				</>
			)}

			<nav>
				{(checkRole('admin') || checkRole('regatta-admin')) &&
					<ul>
						<li>
							<NavLink to='/admin/events'><span className="menu-icon"><FontAwesomeIcon
								icon={faCalendarDays}/></span> <span className="menu-text">Events</span></NavLink>
							<ul className="sub-menu">
								<li>
									<NavLink to='/admin/events'><span className="menu-text">Events</span></NavLink>
								</li>
								<li>
									<NavLink to='/admin/event-types'><span
										className="menu-text">Event Types</span></NavLink>
								</li>
							</ul>
						</li>
						<li>
							<NavLink to='/admin/clubs'><span className="menu-icon"><FontAwesomeIcon
								icon={faSailboat}/></span>
								<span className="menu-text">Clubs</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/ngbs'><span className="menu-icon"><FontAwesomeIcon
								icon={faBuildingNgo}/></span> <span className="menu-text">NGBs</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/countries'><span className="menu-icon"><FontAwesomeIcon
								icon={faEarthAmericas}/></span> <span className="menu-text">Countries</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/coupons'><span className="menu-icon"><FontAwesomeIcon
								icon={faTicket}/></span>
								<span className="menu-text">Coupons</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/users'><span className="menu-icon"><FontAwesomeIcon
								icon={faUser}/></span> <span className="menu-text">Users</span></NavLink>
						</li>
						<li>
							<NavLink to='/admin/logs'><span className="menu-icon"><FontAwesomeIcon
								icon={faHistory}/></span> <span className="menu-text">Logs</span></NavLink>
						</li>
					</ul>
				}

				{(checkRole('admin')) &&
					<ul>
						<li>
							<NavLink to='/admin/settings'><span className="menu-icon"><FontAwesomeIcon
								icon={faGear}/></span> <span className="menu-text">Settings</span></NavLink>
							<ul className="sub-menu">
								<li>
									<NavLink to='/admin/settings/emails'><span
										className="menu-text">Emails</span></NavLink>
								</li>
							</ul>
						</li>
					</ul>
				}
				{checkRole('overseas-steward') && (
					<ul>
						<li>
							<NavLink to='/admin/entries'><span className="menu-icon"><FontAwesomeIcon icon={faClipboardList} /></span> <span className="menu-text">Entries</span></NavLink>
						</li>
					</ul>
				)}
			</nav>
		</div>
	);
};

export default AdminNav;
