import React from "react";

const UserListFilter = ({onRoleChange}) => {

	const userRoles = [
		{ value: 'admin', label: 'Admin' },
		{ value: 'regatta-admin', label: 'Regatta Admin' },
		{ value: 'commentator', label: 'Commentator' },
		{ value: 'press-office', label: 'Press office' },
		{ value: 'overseas-steward', label: 'Overseas Steward' },
		{ value: 'boattent', label: 'Boat Tent' },
		{ value: 'athlete', label: 'Athlete' },
		{ value: 'coach', label: 'Coach' },
		{ value: 'user', label: 'User' },
	];

	return (
		<div>
			<select name="filter_by_role" className="form-select" onChange={(event) => onRoleChange(event.target.value)}>
				<option value="all">All roles</option>
				{userRoles.map((option) => (
					<option key={option.value}
							value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

export default UserListFilter;
