import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select'
import { toast } from 'react-toastify';
import SelectField from '../../../components/forms/select';
import { useSelector } from 'react-redux';
import { getEvent, getEventTypes } from '../../../api/events';

const EventSingle = (props) => {
	// const notify = (text) => toast(text);
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [event, setEvent] = useState(null);
	const [eventTypes, setEventTypes] = useState({});

	// get Events
	const getEvents = useSelector((state) => state.events);

	const eventsList = (getEvents) ? getEvents.events.map((item) => {
		if (item.id !== props.router?.params?.id) {
			return {
				value: item.id,
				label: item.name,
			}
		}
	}) : [];

	const eventTypeList = (eventTypes && eventTypes.length > 0) ? eventTypes.map((item) => {
		return {
			value: item.id,
			label: item.name,
		}
	}) : [];

	const getInitialData = async (id) => {
		try {
			const getEventRes = await getEvent(id);
			if (getEventRes.data.status === 'success') {
				setEvent(getEventRes.data.event);
				setLoading(false);
			}
		} catch (error) {
			console.log('Error', error);
		}
	}
	
	const getEventTypesData = async () => {
		try {
			const getEventTypesRes = await getEventTypes();
			if (getEventTypesRes.data.status === 'success') {
				setEventTypes(getEventTypesRes.data.types);
				setLoading(false);
			}
		} catch (error) {
			console.log('Error', error);
		}
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			getEventTypesData().finally(() => {
				getInitialData(props.router?.params?.id);
			});
		}
	}, []);

	const GetRecords = () => {
		return (
			<div className="row">
				<div className="col-12">
					<h3>Records</h3>
					{(event && event.records) ?
						<table className="table table-striped">
							<thead>
								<tr>
									<th scope="col">Year</th>
									<th scope="col">Crew</th>
									<th scope="col">Barrier Time</th>
									<th scope="col">Fawley Time</th>
									<th scope="col">Finish Time</th>
									<th scope="col">Heat</th>
									<th scope="col">Confirmed</th>
								</tr>
							</thead>
							<tbody>
								{event.records.map((record, index) => (
									<tr key={`record-${index}`}>
										<td>{record.year}</td>
										<td>{record.crew_name}</td>
										<td>{record.barrier_time}</td>
										<td>{record.fawley_time}</td>
										<td>{record.finish_time}</td>
										<td>{(record.heat) ? 'Yes' : 'No'}</td>
										<td>{(record.confirmed) ? 'Yes':'No'}</td>
									</tr>
								))}
							</tbody>
						</table>
						:
						<p>No records found</p>
					}
				</div>
			</div>
		)
	}

	const EventForm = () => {
		// get object from eventTypeList that matches the id of event.event_type
		
		// if entry is an object
		const initialValues = {
			name: (event && event.name) ? event.name : '',
			abbr_name: (event && event.abbr_name) ? event.abbr_name : '',
			event_code: (event && event.event_code) ? event.event_code : '',
			event_type_name: (event && event.event_type_name) ? event.event_type_name : '',
			event_type: (event && event.event_type) ? event.event_type : '',
			min_age: (event && event.min_age) ? event.min_age : '',
			max_age: (event && event.max_age) ? event.max_age : '',
			age_on_date: (event && event.age_on_date) ? event.age_on_date : '',
			price: (event && event.price) ? event.price : '',
			sort: (event && event.sort) ? event.sort : '',
			qsort: (event && event.qsort) ? event.qsort : '',
			composite: (event && event.composite) ? event.composite : '',
			// max_num_group: (event && event.max_num_group) ? event.max_num_group : '',
			max_to_qualify: (event && event.max_to_qualify) ? event.max_to_qualify : '',
			has_qualifiers: (event && event.has_qualifiers === 1) ? true : false,
			min_cox_weight: (event && event.min_cox_weight === 1) ? parseFloat(event.min_cox_weight) : 55,
			doubling: (event && event.doubling) ? JSON.parse(event.doubling) : [],
			ly_entries: (event && event.ly_entries) ? event.ly_entries : '',
			ly_holders: (event && event.ly_holders) ? event.ly_holders : '',
			multiple_entries: (event && event.multiple_entries) ? event.multiple_entries : 2,
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required('Required'),
			abbr_name: Yup.string().required('Required'),
			event_code: Yup.string().required('Required'),
			event_type_name: Yup.string().required('Required'),
			event_type: Yup.string().required('Required'),
			min_age: Yup.string(),
			max_age: Yup.string(),
			age_on_date: Yup.string(),
			price: Yup.number().required('Required'),
			sort: Yup.string().required('Required'),
			qsort: Yup.string().notRequired(),
			doubling: Yup.array().notRequired(),
			// max_num_group: Yup.string().required('Required'),
			max_to_qualify: Yup.string(),
			min_cox_weight: Yup.number(),
			// has_qualifiers: Yup.string().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			const endpoint = (data.id) ? `${API_URL}/admin/event/${data.id}`:`${API_URL}/admin/event`;
			fetch(endpoint, {
				method: (data.id) ? 'PUT':'POST',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((res) => {
					console.log('Success:', res);
					setLoading(false);
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Event saved successfully!", autoClose: 5000});

					if (res.id && !data.id) {
						window.location.href = `/admin/events/edit/${res.id}`;
					}
				})
				.catch((error) => {
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
					console.error('Error:', error);
				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid }) => {
							const eventType = (values.event_type) ? eventTypes.find((item) => item.id === values.event_type):null;

							return (
								<Form>
									<div className="form-group-flex">
										<label htmlFor="username">Event Name:</label>
										<Field type="text" className="form-control" name="name" />
										<ErrorMessage name="name" />
									</div>
									
									<div className="form-group-flex">
										<label htmlFor="username">Abbreviated Event Name:</label>
										<Field type="text" className="form-control" name="abbr_name" />
										<ErrorMessage component="div" name="abbr_name" className="field-error" />
									</div>
	
									<div className="form-group-flex">
										<label htmlFor="event_code">Event Code</label>
										<Field type="text" name="event_code" className="form-control" />
										<ErrorMessage component="div" name="event_code" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_type_name">Event Type Name</label>
										<Field type="text" name="event_type_name" className="form-control" />
										<ErrorMessage component="div" name="event_type_name" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_type">Event Type</label>
										<Field component={SelectField} name="event_type" options={eventTypeList} onChange={(e) => { setFieldValue('event_type', e.value) }} />
										<ErrorMessage component="div" name="event_type" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="composite">Composite</label>
										<Field component={SelectField} name="composite" options={[
											{value: 1, label: 1},
											{value: 2, label: 2},
											{value: 3, label: 3},
											{value: 4, label: 4},
											{value: 5, label: 5},
											{value: 6, label: 6},
											{value: 7, label: 7},
											{value: 8, label: 8},
											{value: 9, label: 9}
										]} onChange={(e) => { setFieldValue('composite', e.value) }} />
										<ErrorMessage component="div" name="composite" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="composite">Allow Doubling</label>
										<Field component={SelectField} isMulti={true} name="doubling" options={eventsList} className="form-check-input" />
										<ErrorMessage component="div" name="doubling" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="composite">Allow Multiple Entries</label>
										<Field type="number" name="multiple_entries" className="form-control"/>
										<ErrorMessage component="div" name="multiple_entries" className="field-error" />
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group-flex">
												<label htmlFor="min_age">Min Age</label>
												<Field type="text" name="min_age" className="form-control" placeholder="18" />
												<ErrorMessage component="div" name="min_age" className="field-error" />
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group-flex">
												<label htmlFor="max_age">Max Age</label>
												<Field type="text" name="max_age" className="form-control" placeholder="60" />
												<ErrorMessage component="div" name="max_age" className="field-error" />
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group-flex">
												<label htmlFor="age_on_date">Max Age on Date (DDMM)</label>
												<Field type="text" name="age_on_date" placeholder="0112" className="form-control" />
												<ErrorMessage component="div" name="age_on_date" className="field-error" />
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group-flex">
												<label htmlFor="price">Min Cox Weight (kg)</label>
												<Field type="text" name="min_cox_weight" className="form-control" />
												<ErrorMessage component="div" name="min_cox_weight" className="field-error" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-3">
											<div className="form-group-flex">
												<label htmlFor="price">Price (£)</label>
												<Field type="number" name="price" className="form-control" />
												<ErrorMessage component="div" name="price" className="field-error" />
											</div>
										</div>
									</div>
									
									<div className="row">
										{/* <div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Has Qualifiers</label>
												<Field type="checkbox" name="has_qualifiers" className="form-check-input" />
												<ErrorMessage component="div" name="has_qualifiers" className="field-error" />
											</div>
										</div> */}
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Event Sort Number</label>
												<Field type="text" name="sort" className="form-control" />
												<ErrorMessage component="div" name="sort" className="field-error"/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Qualifying Order Sort Number</label>
												<Field type="text" name="qsort" className="form-control" />
												<ErrorMessage component="div" name="qsort" className="field-error"/>
											</div>
										</div>
										{/* <div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Max Qualifier Group Number</label>
												<Field type="text" name="max_num_group" className="form-control" />
												<ErrorMessage name="max_num_group" />
											</div>
										</div> */}
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Prescribed Number</label>
												<Field type="text" name="max_to_qualify" className="form-control" />
												<ErrorMessage component="div" name="max_to_qualify" className="field-error" />
											</div>
										</div>
									</div>
									<hr/>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Last Years Entries</label>
												<Field type="text" name="ly_entries" className="form-control" disabled />
												<ErrorMessage component="div" name="ly_entries" className="field-error" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="price">Last Years Holders</label>
												<Field type="text" name="ly_holders" className="form-control" />
												<ErrorMessage component="div" name="ly_holders" className="field-error" />
											</div>
										</div>
									</div>
	
									<div className="form-group form-buttons">
										<button
											className="btn btn-primary btn-block"
											type="submit"
											disabled={(loading)}
										>
											{loading && (
												<span className="spinner-border spinner-border-sm"></span>
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					{(event) ? <h1>Edit Event</h1>:<h1>Add Event</h1>}
				</div>

				<div className="col-12 form-group">
					<EventForm />
					<GetRecords />
				</div>
			</div>
		</div>
	);
}

export default withRouter(EventSingle);
