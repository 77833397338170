import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import SelectField from '../../../components/forms/select';
import { getReport } from '../../../api/Reports';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {qualifiedStatusOptions} from "../../../utils/Helpers";

const ReportOptions = ({ optionsModal, setOptionsModal }) => {
	const [selectedReportItem, setSelectedReport] = useState(null);

	const getEvents = useSelector((state) => state.events);

	const eventListOptions = [
		{ value: '', label: 'All' },
		...getEvents.events.map(event => ({ value: event.id, label: event.name }))
	];

	const reports = {
		// 'qualification-results': {
		// 	endpoint: 'qualification-results',
		// 	description: 'List of qualification results',
		// 	fields: [{
		// 		format: {
		// 			label: 'Format',
		// 			type: 'select',
		// 			options: [
		// 				{ value: 'csv', label: 'CSV' },
		// 				{ value: 'pdf', label: 'PDF' }
		// 			]
		// 		},
		// 		event: {
		// 			label: 'Event',
		// 			type: 'select',
		// 			options: eventListOptions
		// 		},
		// 		crest: {
		// 			label: 'Crest',
		// 			type: 'select',
		// 			options: [
		// 				{ value: 'yes', label: 'Yes' },
		// 				{ value: 'no', label: 'No' }
		// 			]
		// 		},
		// 	}]
		// },
	};

	const groupedReports = [
		{
			label: 'Clubs',
			reports: {
				'clubs': {
					endpoint: 'clubs',
					description: 'Full list of clubs',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'txt', label: 'TXT' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// }
					}]
				},
			},
		},
		{
			label: 'Event',
			reports: {
				'crew-list': {
					endpoint: 'crew-list',
					description: 'Full list of crews T+Q',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' },
								{ value: 'txt', label: 'TXT' }
							]
						},
						event: {
							label: 'Event',
							type: 'select',
							options: eventListOptions
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// }
					}]
				},
				'crew-numbering': {
					endpoint: 'crew-numbering',
					description: 'List of crew numbering before numbering is applied.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// },
					}],
				},
				'entries-extract': {
					endpoint: 'entries',
					description: 'Complete entries extract.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								// { value: 'txt', label: 'TXT' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// }
					}]
				},
				'entries-list': {
					endpoint: 'entries-list',
					description: 'Entries list.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' },
								{ value: 'txt', label: 'TXT' }
							]
						},
						numbered: {
							label: 'Numbered',
							type: 'select',
							options: [
								{ value: 'yes', label: 'Yes' },
								{ value: 'no', label: 'No' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// }
					}]
				},
				'programme': {
					endpoint: 'programme',
					description: 'Programme',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'txt', label: 'TXT' },
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// },
					}],
				},
				'event-extract': {
					endpoint: 'event-extract',
					description: 'Complete event extract.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								// { value: 'txt', label: 'TXT' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// }
					}]
				},
			},
		},
		{
			label: 'Rowers',
			reports: {
				'rowers': {
					endpoint: 'rowers',
					description: 'To allow HRR to manipulate rower data eg marketing preferences, club history, event history, age demographic etc.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' }
							]
						},
						this_year: {
							label: 'This year\'s Regatta',
							type: 'checkbox',
							value: 'yes'
						},
					}],
				},
				'doubling-up': {
					endpoint: 'doubling-up',
					description: 'List of athletes participating in more than 1 event.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						event: {
							label: 'Event',
							type: 'select',
							options: eventListOptions
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// },
					}],
				},
			},
		},
		{
			label: 'Qualifying',
			reports: {
				'required-to-qualify-p2': {
					endpoint: 'required-to-qualify-p2',
					description: 'List of crews required to qualify P2',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								// { value: 'csv', label: 'CSV' },
								// { value: 'txt', label: 'TXT' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						event: {
							label: 'Event',
							type: 'select',
							options: eventListOptions
						},
					}],
				},
				'required-to-qualify': {
					endpoint: 'required-to-qualify',
					description: 'List of crews required to qualify',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'txt', label: 'TXT' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						event: {
							label: 'Event',
							type: 'select',
							options: eventListOptions
						},
					}],
				},
				'required-to-qualify-by-group': {
					endpoint: 'required-to-qualify-by-group',
					description: 'List of crews required to qualify by group',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'txt', label: 'TXT' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						// event: {
						// 	label: 'Event',
						// 	type: 'select',
						// 	options: eventListOptions
						// },
					}],
				},
				'chairman-qualifying-results': {
					endpoint: 'chairman-qualifying-results',
					description: 'List of chairman qualifying results',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'txt', label: 'TXT' },
								{ value: 'pdf', label: 'PDF' }
								// { value: 'csv', label: 'CSV' }
							]
						},
						event: {
							label: 'Event',
							type: 'select',
							options: eventListOptions
						},
						// crest: {
						// 	label: 'Crest',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'yes', label: 'Yes' },
						// 		{ value: 'no', label: 'No' }
						// 	]
						// },
					}],
				},
				'qualifying-results': {
					endpoint: 'qualifying-results',
					description: 'List of qualifying results',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'txt', label: 'TXT' },
								{ value: 'pdf', label: 'PDF' },
								{ value: 'csv', label: 'CSV' }
							]
						},
						event: {
							label: 'Event',
							type: 'select',
							options: eventListOptions
						},
						crest: {
							label: 'Crest',
							type: 'select',
							options: [
								{ value: 'yes', label: 'Yes' },
								{ value: 'no', label: 'No' }
							]
						},
					}],
				},
				'non-qualifiers': {
					endpoint: 'non-qualifiers',
					description: 'Gives a master list of crews that failed to qualify',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'txt', label: 'TXT' },
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// },
					}],
				},
				'changed-identifiers': {
					endpoint: 'changed-identifiers',
					description: 'Changed Identifiers',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' },
								{ value: 'txt', label: 'TXT' }
							]
						}
					}],
				},
				'drawslips': {
					endpoint: 'drawslips',
					description: 'Drawslips',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' },
								{ value: 'txt', label: 'TXT' }
							]
						},
					}],
				},
			},
		},
		{
			label: 'Boat Tent',
			reports: {
				'athletes-not-checked-in': {
					endpoint: 'athletes-not-checked-in',
					description: 'List of athletes not currently checked in.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						"crew-name-only": {
							label: 'Show crew name only',
							type: 'select',
							options: [
								{ value: false, label: 'No' },
								{ value: true, label: 'Yes' }
							]
						},
					}],
				},
				'athletes-under-18': {
					endpoint: 'athletes-under-18',
					description: 'List of athletes under 18.',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						// ordering: {
						// 	label: 'Order By',
						// 	type: 'select',
						// 	options: [
						// 		{ value: 'name', label: 'Name' },
						// 		{ value: 'id', label: 'ID' }
						// 	]
						// },
					}],
				},
				'competitor-badges-packing-slip': {
					endpoint: 'competitor-badges-packing-slip',
					description: 'Description needed here (plus title of report)',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' }
							]
						},
						qualification_status: {
							label: 'Qualification Status',
							type: 'select',
							options: qualifiedStatusOptions
						},
					}],
				},
			}
		},
		{
			label: 'Timetable',
			reports: {
				'daily-timetable': {
					endpoint: 'daily-timetable',
					description: 'Daily Timetable',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' },
								{ value: 'pdf', label: 'PDF' },
								{ value: 'txt', label: 'TXT' }
							]
						},
						day: {
							label: 'Day',
							type: 'select',
							options: [
								// { value: '', label: 'All', selected: true },
								{ value: 'tues', label: 'Tuesday' },
								{ value: 'weds', label: 'Wednesday' },
								{ value: 'thurs', label: 'Thursday' },
								{ value: 'fri', label: 'Friday' },
								{ value: 'sat', label: 'Saturday' },
								{ value: 'sun', label: 'Sunday' }
							]
						},
						crest: {
							label: 'Crest',
							type: 'select',
							options: [
								{ value: 'yes', label: 'Yes' },
								{ value: 'no', label: 'No' }
							]
						},
						numbered: {
							label: 'Numbered',
							type: 'select',
							options: [
								{ value: 'yes', label: 'Yes' },
								{ value: 'no', label: 'No' }
							]
						},
					}],
				},
			}
		},
		{
			label: 'General Admin',
			reports: {
				'5-year-record': {
					endpoint: 'five-year-record',
					description: '5 Year Rower Record',
					fields: [{
						format: {
							label: 'Format',
							type: 'select',
							options: [
								{ value: 'csv', label: 'CSV' }
							]
						}
					}],
				},
			}
		}
	];


	const getReportDownload = async (report, options) => {
		const toastId = toast("Generating report...", { autoClose: false, type: 'info' });
		try {
			const getReportData = await getReport(report, options.format, options);

			if (options.format === 'csv') {
				if (getReportData.data.status === "success") {
					const blob = new Blob(
						[Uint8Array.from(atob(getReportData.data.report), c => c.charCodeAt(0))],
						{ type: 'application/csv' }
					);

					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = options.reportType + '.csv';
					link.click();

					toast.update(toastId, { render: "Downloading Report", type: 'success', autoClose: 5000 });
				}
			} else if (options.format === 'txt') {
				const blob = new Blob(
					[Uint8Array.from(atob(getReportData.data.report), c => c.charCodeAt(0))],
					{ type: 'text/plain', endings: 'native', encoding: 'utf-8' }
				);

				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = options.reportType + '.txt';
				link.click();

				toast.update(toastId, { render: "Downloading Report", type: 'success', autoClose: 5000 });
			} else if (options.format === 'pdf') {
				const blob = new Blob(
					[Uint8Array.from(atob(getReportData.data.report), c => c.charCodeAt(0))],
					{ type: 'application/pdf', encoding: 'utf-8' }
				);

				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = options.reportType + '.pdf';
				link.click();

				toast.update(toastId, { render: "Downloading Report", type: 'success', autoClose: 5000 });
			}
		} catch (error) {
			toast.update(toastId, { render: "Unable to download report", type: 'error', autoClose: 5000 });
		}
	};

	const reportOptions = groupedReports.map((key) => {
		return {
			label: key.label,
			options: Object.keys(key.reports).map((key) => {
				return {
					value: key,
					label: key.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) // Beautify the key as label
				}
			})
		}
	});

	const handleSubmit = (values) => {
		if (!values.endpoint) return;
		getReportDownload(values.endpoint, values);
	};

	const renderFields = (selectedReport) => {
		if (!selectedReport) return null;

		const report = getReportByKey(selectedReport);

		return report.fields.map((fields, i) => {
			return Object.entries(fields).map((field, k) => {
				if (field[1].type === 'select') {
					return <div key={field[0]} className="mb-2">
						<label>{field[1].label}</label>
						<Field component={SelectField} name={field[0]} options={field[1].options} />
					</div>;
				} else if (field[1].type === 'checkbox') {
					return <div key={field[0]} className="mb-2 form-check">
						<label htmlFor="terms" className="form-check-label">
							{field[1].label}
							<Field
								name={field[0]}
								type={field[1].type}
								value={field[1].value}
								className="form-check-input"
							/>
						</label>
					</div>
				} else {
					return <div key={field[0]} className="mb-2">
						<label>{field[1].label}</label>
						<Field
							name={field[0]}
							type={field[1].type}
							className="form-control"
						/>
					</div>;
				}
			});
		});
	};

	const generateInitialValues = (selectedReportItem) => {
		if (!selectedReportItem || !reports[selectedReportItem]) return {
			endpoint: '',
			reportType: ''
		};

		const initialVals = {};

		initialVals.reportType = selectedReportItem;

		const report = getReportByKey(selectedReportItem);

		report.fields.forEach((fieldGroup) => {
			Object.entries(fieldGroup).forEach(([fieldName, fieldDetails]) => {
				// For select fields, set initial value as the first option's value or an empty string
				if (fieldDetails.type === 'select') {
					initialVals[fieldName] = (fieldDetails.options[0] && fieldDetails.options[0].value) || '';
				} else {
					// For other types of fields, you can set initial values accordingly
					initialVals[fieldName] = '';
				}
			});
		});

		initialVals.endpoint = reports[selectedReportItem].endpoint;

		return initialVals;
	};

	const getReportByKey = (report) => {
		return groupedReports.find((r) => {
			return Object.keys(r.reports).find(key => report === key);
		})?.reports[report];
	};

	const initialValues = useMemo(() => generateInitialValues(selectedReportItem), [selectedReportItem, generateInitialValues]);

	return (
		<>
			{optionsModal && (
				<div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Report Options</h5>
								<button type="button" className="btn btn-none" aria-label="Close" onClick={() => setOptionsModal(false)}>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</div>
							<div className="modal-body">
								<Formik
									enableReinitialize={true}
									initialValues={initialValues}
									onSubmit={handleSubmit}
									>
									{({ values, setFieldValue }) => (
										<Form>
											<div>
												<label>Report</label>
												<Field
													name="reportType"
													component={SelectField}
													options={reportOptions}
													onChange={(option) => {
														setFieldValue("reportType", option);
														setSelectedReport(option);
														setFieldValue('endpoint', getReportByKey(option).endpoint);
													}}
													className="form-control"
												/>

												<Field name="endpoint" type="hidden" />
											</div>

											{(values.reportType) &&
												<div className="d-block mt-1 mb-2">
													<strong>Description:</strong> {getReportByKey(values.reportType).description}
												</div>
											}

											{renderFields(values.reportType)}

											<div className="text-end">
												<hr/>
												<button type="submit" className="btn btn-primary">Generate Report</button>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ReportOptions;
