import { Field, Form, Formik} from "formik";
import React from "react";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {adminBulkUpdateUsers} from "../../api/user";

const UserListActions = ({selectedUsers, onActionCompleted}) => {
	if (selectedUsers.length === 0) {
		return;
	}

	const userListActionOptions = [
		{
			value: 'block',
			label: 'Block'
		},
		{
			value: 'unblock',
			label: 'Unblock'
		},
	];

	const UserActionSchema = Yup.object().shape({
		action: Yup.string().required('Required'),
	});

	const handleUserListAction = async (values) => {
		if (window.confirm('Are you sure you wish to perform this action?')) {
			const toastId = toast("Updating users...", {autoClose: false});

			try {
				const response = await adminBulkUpdateUsers({
					user_ids: selectedUsers,
					action: values.action
				});
				if (response.data.status === 'success') {
					toast.update(toastId, {type: toast.TYPE.SUCCESS, render: response.data.message, autoClose: 5000});
					onActionCompleted();
				} else {
					toast.update(toastId, {
						type: toast.TYPE.ERROR,
						render: "Error bulk updating users",
						autoClose: 5000
					});
				}
			} catch (error) {
				console.log('errr', error);
				toast.update(toastId, {type: toast.TYPE.ERROR, render: "Error bulk updating users", autoClose: 5000});
			}
		}
	}

	return (
		<div>
			<Formik
				initialValues={{ action: '' }}
				validationSchema={UserActionSchema}
				onSubmit={(values, { setSubmitting }) => {
					handleUserListAction(values).finally(() => setSubmitting(false))
				}}
			>
				{({ isSubmitting, values }) => (
					<Form>
						<div className="d-flex gap-1">
							<div>
								<Field as="select" name="action" className="form-select">
									<option value="" disabled>
										Bulk actions
									</option>
									{userListActionOptions.map((option) => (
										<option key={option.value}
												value={option.value}>
											{option.label}
										</option>
									))}
								</Field>
							</div>
							<button type="submit" className="btn btn-primary" disabled={isSubmitting || values.action === ''}>
								Apply
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default UserListActions;
