import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { entryStatusOptions, qualifiedStatusOptions, selectedOptions } from '../utils/Helpers';
import {searchUsers} from "../api/user";

const EntryFilter = ({ handleFilters, filters }) => {
	const [status, setStatus] = useState(null);
	const [selected, setSelected] = useState(null);
	const [qualified, setQualified] = useState(null);
	const [event, setEvent] = useState(null);
	const [checkedBy, setCheckedBy] = useState(null);
	const [events, setEvents] = useState(null);
	const [adminUsers, setAdminUsers] = useState(null);

	const eventsList = useSelector((state) => state.events);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		fetchAdminUsers();
		mapEventsToSelect();
	}, []);

	useEffect(() => {
		setFiltersFromURL();
	}, [events, adminUsers]);

	const fetchAdminUsers = async () => {
		try {
			const data = {
				role: 'admin',
				term: '',
			}

			const response = await searchUsers(data);
			if (response.status === 200) {
				const adminOptions = [{ value: '', label: 'All' }];

				Object.entries(response.data.data).forEach(([key, user]) => {
					adminOptions.push({ value: user.id, label: `${user.first_name} ${user.last_name}` });
				});

				setAdminUsers(adminOptions);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	const mapEventsToSelect = () => {
		const eventOptions = [{ value: '', label: 'All' }];
		Object.entries(eventsList.events).forEach(([key, value]) => {
			eventOptions.push({ value: value.id, label: value.name });
		});
		setEvents(eventOptions);
	};

	const findOptionLabel = (options, value) => {
		if (!options) return value;
		const intValue = isNaN(value) ? value : parseInt(value, 10);
		const option = options.find((option) => option.value === intValue);
		return option ? option.label : value;
	};

	const getFilterState = (options, value) => {
		if (!value) return null;
		return { value, label: findOptionLabel(options, value) };
	};

	const setFiltersFromURL = () => {
		const searchParams = new URLSearchParams(location.search);
		const newFilters = {
			status: getFilterState(entryStatusOptions, searchParams.get('status')),
			selected: getFilterState(selectedOptions, searchParams.get('selected')),
			qualified: getFilterState(qualifiedStatusOptions, searchParams.get('qualified')),
			event: getFilterState(events, searchParams.get('event')),
			checkedBy: getFilterState(adminUsers, searchParams.get('checkedBy')),
		};
		setStatus(newFilters.status);
		setSelected(newFilters.selected);
		setQualified(newFilters.qualified);
		setEvent(newFilters.event);
		setCheckedBy(newFilters.checkedBy);
		handleFilters(newFilters);
	};

	const updateURLParams = (newFilters) => {
		const searchParams = new URLSearchParams(location.search);
		Object.keys(newFilters).forEach((key) => {
			if (newFilters[key]) {
				searchParams.set(key, newFilters[key].value);
			} else {
				searchParams.delete(key);
			}
		});
		navigate({ search: searchParams.toString() });
	};

	const handleStatusChange = (selectedOption) => {
		const newStatus = selectedOption.value === '' ? null : selectedOption;
		setStatus(newStatus);
		const newFilters = { status: newStatus, selected, event, qualified, checkedBy };
		handleFilters(newFilters);
		updateURLParams(newFilters);
	};

	const handleSelectedChange = (selectedOption) => {
		const newSelected = selectedOption.value === '' ? null : selectedOption;
		setSelected(newSelected);
		const newFilters = { status, selected: newSelected, event, qualified, checkedBy };
		handleFilters(newFilters);
		updateURLParams(newFilters);
	};

	const handleEventChange = (selectedOption) => {
		const newEvent = selectedOption.value === '' ? null : selectedOption;
		setEvent(newEvent);
		const newFilters = { status, selected, event: newEvent, qualified, checkedBy };
		handleFilters(newFilters);
		updateURLParams(newFilters);
	};

	const handleQualifiedChange = (selectedOption) => {
		const newQualified = selectedOption.value === '' ? null : selectedOption;
		setQualified(newQualified);
		const newFilters = { status, selected, event, qualified: newQualified, checkedBy };
		handleFilters(newFilters);
		updateURLParams(newFilters);
	};

	const handleCheckedByChange = (selectedOption) => {
		const newCheckedBy = selectedOption.value === '' ? null : selectedOption;
		setCheckedBy(newCheckedBy);
		const newFilters = { status, selected, event, qualified, checkedBy: newCheckedBy };
		handleFilters(newFilters);
		updateURLParams(newFilters);
	};

	return (
		<div className="filter-container row">
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Entry Status</label>
				<Select value={filters.status} onChange={handleStatusChange} options={entryStatusOptions} />
			</div>
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Selected</label>
				<Select value={filters.selected} onChange={handleSelectedChange} options={selectedOptions} />
			</div>
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Qualified Status</label>
				<Select value={filters.qualified} onChange={handleQualifiedChange} options={qualifiedStatusOptions} />
			</div>
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Event</label>
				<Select value={filters.event} onChange={handleEventChange} options={events} />
			</div>
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Data checked by</label>
				<Select value={filters.checkedBy} onChange={handleCheckedByChange} options={adminUsers} />
			</div>
		</div>
	);
};

export default EntryFilter;
