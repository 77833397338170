import React, { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dateFormatterChange, dateFormatterBlur } from '../../../utils/dateFormatter';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SelectField from '../../../components/forms/select';
import {
	statusList,
	selectedList,
	rankList,
	qualifiedOptions,
	getCrewMemberOrder,
	checkRole, qualifiedStatusOptions
} from '../../../utils/Helpers';
import {saveAdminEntries, renameAdminEntry, printAdminEntry, getCoaches} from '../../../api/entries';
import { getClubs } from '../../../api/clubs';
import { getEvents } from '../../../api/events';
import { getAdminEntry } from '../../../api/entries';
import { emailSend } from '../../../api/triggers';
import LogTable from '../../../components/LogTable';
import Select from "react-select";

const SingleEntry = (props) => {
	// get the entry id from the url
	const entryId = props.router.params.id;

	const getEventList = useSelector((state) => state.events);

	const [loading, setLoading] = useState(true);
	const [entry, setEntry] = useState({});
	const [events, setEvents] = useState(false);
	const [coaches, setCoaches] = useState(false);
	const [coachOptions, setCoachOptions] = useState([]);
	const [clubs, setClubs] = useState(false);
	const [selectedClubIds, setSelectedClubIds] = useState([]);

	const user = useSelector(state => state.user);
	const isOverseasSteward = checkRole(user.user.role, 'overseas-steward', true);
	const isPressOffice = checkRole(user.user.role, 'press-office', true);

	const isAdmin = checkRole(user.user.role, 'admin', false);

	const editable = !isOverseasSteward && !isPressOffice;

	useEffect(() => {
		if (!clubs) {
			(async () => {
				try {
					const fetchClubs = await getClubs();
					if (fetchClubs.data.status === 'success') {
						fetchClubs.data.clubs.forEach((item) => {
							// clubData['label'] = item.;
							item.label = item.name;
							item.value = item.id;
						});
	
						setClubs(fetchClubs.data.clubs);
					}
				} catch (error) {
					// TODO: Handle errors
					console.log(error);
				}
			})();
		}

		if (!events) {
			(async () => {
				try {
					const fetchEvents = await getEvents();

					if (fetchEvents.data.status === 'success') {
						setEvents(fetchEvents.data.events);
					}
				} catch (error) {
					// TODO: Handle errors
					console.log(error);
				}
			})();
		}

		if (!coaches) {
			(async () => {
				try {
					const fetchCoaches = await getCoaches();
					const tempOptions = [];

					if (fetchCoaches.status === 200) {
						setCoaches(fetchCoaches.data);

						fetchCoaches.data.forEach((item) => {
							tempOptions.push({ value: item.id, label: `${item.first_name} ${item.last_name}` });
						});
						setCoachOptions(tempOptions);
					}
				} catch (error) {
					console.log(error);
				}
			})();
		}
	}, []);

	const eventsList = (getEventList.events && getEventList.events.length > 0) ? getEventList.events.map((item) => {
		return {
			value: item.id,
			label: item.name,
			qty: item.event_types.athlete_qty,
		}
	}) : [];

	useEffect(() => {
			(async () => {
				try {
					const fetchEntries = await getAdminEntry(entryId);
					if (fetchEntries.data.status === 'success') {
						// reorder crew_members
						const crewMembers = getCrewMemberOrder(fetchEntries.data.entry.crew.crew_members);
						fetchEntries.data.entry.crew.crew_members = crewMembers;

						setEntry(fetchEntries.data.entry);
						setLoading(false);
					}
				} catch (error) {
					// TODO: Error handling
					console.log(error);
				}
			})();
	}, [clubs]);

	const checkClubValue = (selectedOption, index, setFieldValue) => {
		// get the club by id
		if (selectedClubIds.length <= entry.event.composite) {
			const newSelectedClubs = [...selectedClubIds];
			if (!selectedClubIds.includes(selectedOption)) {
				newSelectedClubs.push(selectedOption);
			}

			setFieldValue(`athletes[${index}].club`, selectedOption, () => {
				setSelectedClubIds(newSelectedClubs);
			});
		}

		if (entry.event.composite > 0) {
			// If this club is not yet in selectedClubs and selecting it would exceed the limit
			if (!selectedClubIds.includes(selectedOption) && selectedClubIds.length >= entry.event.composite) {
				toast("You have reached the maximum number of clubs for this event.", { autoClose: true, type: toast.TYPE.ERROR });
				setFieldValue(`athletes[${index}].club`, '', () => {
					// setSelectKey(prevKey => prevKey + 1);
				});
				return;
			}
		}
	};

	const handleRenameEntry = async () => {
		const toastId = toast("Renaming entry...", { autoClose: false });

		try {
			const rename = await renameAdminEntry(entryId);
			if (rename.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry renamed", autoClose: 5000 });
				setEntry(rename.data.entry);
			}
		} catch (error) {
			toast.update(toastId, { type: 'error', render: "Error renaming entry", autoClose: 5000 });
		}
	};

	const handleDateChange = (value, name, setFieldValue, period = "past") => {
		const formattedValue = dateFormatterChange(value.target.value, period);
		setFieldValue(name, formattedValue);
	};

	const handleDateBlur = (value, name, setFieldValue, period = "past") => {
		const formattedValue = dateFormatterBlur(value.target.value, period);
		setFieldValue(name, formattedValue);
	};

	const handleReorder = (currentIndex, newIndex, values, setFieldValue) => {
		const athletesCopy = [...values.athletes];

		let currentAthlete = { ...athletesCopy[currentIndex] };
		let newAthlete = { ...athletesCopy[newIndex] };

		// Swap all values except position
		[currentAthlete, newAthlete] = [newAthlete, currentAthlete];

		// Re-assign the position values to the originals
		currentAthlete.position = athletesCopy[currentIndex].position;
		newAthlete.position = athletesCopy[newIndex].position;

		athletesCopy[currentIndex] = currentAthlete;
		athletesCopy[newIndex] = newAthlete;

		setFieldValue('athletes', athletesCopy);
	};

	const getDeclarationSignings = () => {
		const declarationCountries = entry.order?.declaration?.declaration?.countries;
		const declarationClubs = entry.order?.declaration?.declaration?.clubs;
		const crewMembers = entry.crew.crew_members;

		if (!declarationCountries || !declarationClubs || !crewMembers) {
			return <p>No declaration found.</p>;
		}

		const matches = crewMembers.reduce((acc, member) => {
			const countryMatch = declarationCountries[member.club.country_code];
			const clubMatch = declarationClubs[member.club.id];

			// Use a composite key to uniquely identify country-club combinations
			const uniqueKey = `${member.club.country_code}-${member.club.id}`;

			if ((countryMatch || clubMatch) && !acc.seen[uniqueKey]) {
				acc.seen[uniqueKey] = true; // Mark this combination as seen

				acc.data.push({
					country: member.club?.country?.country,
					signed: clubMatch ? clubMatch.signed : null,
					contact: clubMatch ? clubMatch.contact : null,
				});
			}

			return acc;
		}, { seen: {}, data: [] }).data; // Extract the data array at the end

		return matches.length > 0 ? (
			matches.map((match, index) => (
				<div key={index}>
					{match.country && <p>Country: {match.country}</p>}
					{match.signed && <p>Signed: {match.signed}</p>}
					{match.contact && <p>Contact: {match.contact}</p>}
				</div>
			))
		) : (
			<p>No declaration found.</p>
		);
	}

	const resendAthleteRegistration = () => {
		const toastId = toast("Resending athlete registration email...", { autoClose: false });

		(async () => {
			const sendEmail = await emailSend(entry.id, 'athlete_registration_resend');
			if (sendEmail.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Athlete registration email sent", autoClose: 5000 });
			} else {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error sending email", autoClose: 5000 });
			}
		})();
	};

	const resendCoachRegistration = () => {
		const toastId = toast("Resending coach registration email...", { autoClose: false });

		(async () => {
			const sendEmail = await emailSend(entry.id, 'coach_registration_resend');
			if (sendEmail.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Coach registration email sent", autoClose: 5000 });
			} else {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error sending email", autoClose: 5000 });
			}
		})();
	};

	const getClubData = (currentAthleteValues, unique = false) => {
		if (currentAthleteValues) {
			let clubData = currentAthleteValues.map(athlete => {
				if (athlete.club && clubs) {
					return clubs.find((club) => club.id === athlete.club);
				}
			}).filter(club => club !== undefined);

			if (unique) {
				clubData = [...new Set(clubData)];
			}

			return clubData;
		}

		return null;
	}

	const EventForm = () => {
		const steerIndex = (entry && entry.crew.crew_members?.findIndex(member => member.steer === 1)) ? entry.crew.crew_members?.findIndex(member => member.steer === 1) : null;

		const initialValues = {
			athletes: Array.from({ length: entry.event.event_types.athlete_qty }, (_, index) => {
				return {
					position: index === 0 ? 'Bow' :
						index === (entry.event?.event_types?.athlete_qty - 1) && entry.event?.event_types?.cox === 1 ? 'Cox' :
						index === (entry.event?.event_types?.athlete_qty - 2) && entry.event?.event_types?.cox === 1 ? 'Stroke' :
						index === (entry.event?.event_types?.athlete_qty - 1) && entry.event?.event_types?.cox === 0 ? 'Stroke' :
						index + 1,
					// steer: 0,
					club: (entry && entry.crew?.crew_members[index]?.club_id) ? entry.crew.crew_members[index]?.club_id : '',
					first_name: (entry && entry.crew?.crew_members[index]?.athletes && entry.crew?.crew_members[index]?.athletes?.first_name) ? entry.crew.crew_members[index]?.athletes?.first_name : 'sddfd',
					last_name: (entry && entry.crew?.crew_members[index]?.athletes && entry.crew?.crew_members[index]?.athletes?.last_name) ? entry.crew.crew_members[index]?.athletes?.last_name : '',
					dob: (entry && entry.crew?.crew_members[index] && entry.crew?.crew_members[index]?.athletes?.dob) ? entry.crew.crew_members?.[index]?.athletes?.dob : '',
					initials: {
						0: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[0]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[0] : '',
						1: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[1]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[1] : '',
						2: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[2]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[2] : '',
					},
					previously_rowed: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.previously_rowed) ? entry.crew.crew_members?.[index]?.athletes?.previously_rowed : 0,
				}
			}),
			crew: {
				emergency_contact_name: (entry && entry.crew.emergency_contact_name) ? entry.crew.emergency_contact_name : '',
				emergency_contact: (entry && entry.crew.emergency_contact) ? entry.crew.emergency_contact : '',
				crew_ranking_index: (entry && entry.crew.crew_ranking_index) ? entry.crew.crew_ranking_index : '',
				crew_name: (entry && entry.crew.crew_name) ? entry.crew.crew_name : '',
				full_name: (entry && entry.crew.full_name) ? entry.crew.full_name : '',
				full_name_country: (entry && entry.crew.full_name_country) ? entry.crew.full_name_country : '',
				short_name: (entry && entry.crew.short_name) ? entry.crew.short_name : '',
				draw_slip_name: (entry && entry.crew.draw_slip_name) ? entry.crew.draw_slip_name : '',
				timetable_name: (entry && entry.crew.timetable_name) ? entry.crew.timetable_name : '',
				broadcast_name: (entry && entry.crew.broadcast_name) ? entry.crew.broadcast_name : '',
				twitter_name: (entry && entry.crew.twitter_name) ? entry.crew.twitter_name : '',
				crew_rank: (entry && entry.crew.crew_rank) ? entry.crew.crew_rank : '',
				club_id_1: (entry && entry.crew.club_id_1) ? entry.crew.club_id_1 : '',
				club_id_2: (entry && entry.crew.club_id_2) ? entry.crew.club_id_2 : '',
				crew_number: (entry && entry.crew.crew_number) ? entry.crew.crew_number : '',
			},
			coach: {
				first_name: (entry && entry.crew.coach && entry.crew.coach.first_name) ? entry.crew.coach.first_name : '',
				last_name: (entry && entry.crew.coach && entry.crew.coach.last_name) ? entry.crew.coach.last_name : '',
				email: (entry && entry.crew.coach && entry.crew.coach.email) ? entry.crew.coach.email : '',
				phone: (entry && entry.crew.coach && entry.crew.coach.mobile) ? entry.crew.coach.mobile : '',
				initials: {
					0: (entry && entry.crew.coach && entry.crew.coach.initial_parts?.[0]) ? entry.crew.coach.initial_parts?.[0] : '',
					1: (entry && entry.crew.coach && entry.crew.coach.initial_parts?.[1]) ? entry.crew.coach.initial_parts?.[1] : '',
					2: (entry && entry.crew.coach && entry.crew.coach.initial_parts?.[2]) ? entry.crew.coach.initial_parts?.[2] : '',
				},
				coach: (entry && entry?.crew?.coach && entry?.crew?.coach.id) ? { value: entry?.crew?.coach.id, label: `${entry?.crew?.coach.first_name} ${entry?.crew?.coach.last_name}` } : null,
			},
			steer: (entry && steerIndex && steerIndex !== -1) ? steerIndex.toString() : '0',
			supplementary_info: (entry && entry.supplementary_info) ? entry.supplementary_info : '',
			arrival_date: (entry && entry.arrival_date) ? entry.arrival_date : '',
			event: (entry && entry.event.id) ? entry.event.id : '',
			status: (entry && entry.status) ? entry.status : 0,
			selected: (entry && entry.selected) ? entry.selected : 0,
			qualification_status: (entry && entry.qualification_status) ? entry.qualification_status : 0,
			data_checked: !!(entry && entry.data_checked),
			withdrawal_reason: (entry && entry.withdrawal_reason) ? entry.withdrawal_reason : '',
		};

		const validationSchema = Yup.object().shape({
			athletes: Yup.array()
				.of(
					Yup.object().shape({
						position: Yup.string().required('Required'),
						club: Yup.string().required('Required'),
						first_name: Yup.string().required('Required'),
						last_name: Yup.string().required('Required'),
						dob: Yup.string().required('Required'),
						previously_rowed: Yup.string().required('Required'),
					})
				)
				.required('Must have athletes'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			// setLoading(true);
			const toastId = toast("Saving...", { autoClose: false });

			setSubmitting(true);

			const data = {
				...values,
				entry_id: entry.id,
				event_id: entry.id,
				crew_id: entry.id,
			};


			console.log(values);
			// fetch put
			try {
				const adminEntries = await saveAdminEntries(entryId, data);
				if (adminEntries.data.status === 'success') {
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry saved", autoClose: 5000 });
					return;
				}
			} catch (error) {
				console.log(error);
			}
		};

		const handlePrintEntry = async () => {
			const toastId = toast("Generating report...", { autoClose: false, type: 'info' });
			try {
				const getAdminEntryPrint = await printAdminEntry(entryId);

				if (getAdminEntryPrint.data.status === 'success') {
					const blob = new Blob(
						[Uint8Array.from(atob(getAdminEntryPrint.data.report), c => c.charCodeAt(0))],
						{ type: 'application/pdf' }
					);

					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = 'entry-' + entryId + '.pdf';
					link.click();

					toast.update(toastId, { render: "Downloading Entry", type: 'success', autoClose: 5000 });
				}
			} catch (error) {
				toast.update(toastId, { render: "Unable to download entry", type: 'error', autoClose: 5000 });
			}
		}

		const handleCoachChange = (selectedOption, setFieldValue, values) => {
			const coachData = coaches.find(coach => coach.id == selectedOption.value);
			console.log(coachData);
			let newCoach = { ...values.coach,
				coach: selectedOption,
				first_name: coachData.first_name,
				last_name: coachData.last_name,
				email: coachData.email,
				phone: coachData.mobile,
				initials: {
					0: coachData.initials ? coachData.initials[0] : '',
					1: coachData.initials ? coachData.initials[1] : '',
					2: coachData.initials ? coachData.initials[2] : '',
				}
			};

			setFieldValue('coach', newCoach);
		}

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ submitForm, values, errors, touched, setFieldValue, isValid, setValues }) => {
							const selectedClubIdData = getClubData(values.athletes, true)
								.map(club => {
									return {
										value: club.id,
										label: club.name,
										isDisabled: (values.crew.club_id_1 === club.id || values.crew.club_id_2 === club.id) ? true : false
									};
								});

							return (
							<Form>
								<div>
									{(entry?.user) &&
										<>
										<table className="table table-striped">
											<tr>
												<td width="150px">User</td>
												<td>{entry.user.first_name} {entry.user.last_name}</td>
											</tr>
											<tr>
												<td width="150px">User Email</td>
												<td>{entry.user.email}</td>
											</tr>
										</table>
										<hr/>
										</>
									}
									<table className="table table-striped">
										<tr>
											<td>Crew ID</td>
											<td>{entry.crew.identifier}</td>
										</tr>
										<tr>
											<td width="150px">Crew Number</td>
											<td>
												<Field type="text" name="crew[crew_number]" className="form-control" disabled={!editable}/>
											</td>
										</tr>
										<tr>
											<td width="150px">Crew Rank</td>
											<td>
												<Field component={SelectField} name="crew[crew_rank]" className="form-control" options={rankList} isDisabled={!editable} />
											</td>
										</tr>
									</table>
									<hr/>
									<h3>Crew Naming</h3>
									<table className="table table-striped">
										<tbody>
										<tr>
											<td width="150px">Crew Name</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.crew_name && (
														<span style={{ flexBasis: '80px' }} className="text-success">{values.crew.crew_name.length} / ∞</span>
													)}
													<Field type="text" name="crew[crew_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Full Name</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.full_name && (
														<span style={{ flexBasis: '80px' }} className={(values.crew.full_name.length > 150) ? 'text-danger' : 'text-success'}>{values.crew.full_name.length} / 150</span>
													)}
													<Field type="text" name="crew[full_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Full Name Country</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.full_name_country && (
														<span style={{flexBasis: '80px'}} className={(values.crew.full_name_country.length > 200) ? 'text-danger' : 'text-success'}>{values.crew.full_name_country.length} / 200</span>
													)}
													<Field type="text" name="crew[full_name_country]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Short Name</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.short_name && (
														<span style={{ flexBasis: '80px' }} className={(values.crew.short_name.length > 35) ? 'text-danger':'text-success'}>{values.crew.short_name.length} / 35</span>
													)}
													<Field type="text" name="crew[short_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Drawslip Name</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.draw_slip_name && (
														<span style={{ flexBasis: '80px' }} className={(values.crew.draw_slip_name.length > 40) ? 'text-danger' : 'text-success'}>{values.crew.draw_slip_name.length} / 40</span>
													)}
													<Field type="text" name="crew[draw_slip_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Timetable Name</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.timetable_name && (
														<span style={{ flexBasis: '80px' }} className={(values.crew.timetable_name.length > 36) ? 'text-danger' : 'text-success'}>{values.crew.timetable_name.length} / 36</span>
													)}
													<Field type="text" name="crew[timetable_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Broadcast Name</td>
											<td>
												<div className="d-flex align-items-center">
													{values.crew.broadcast_name && (
														<span style={{ flexBasis: '80px' }} className={(values.crew.broadcast_name.length > 25) ? 'text-danger':'text-success'}>{values.crew.broadcast_name.length} / 25</span>
													)}
													<Field type="text" name="crew[broadcast_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										<tr>
											<td width="150px">Twitter Name</td>
											<td>
												<div className="d-flex align-items-center">
													<Field type="text" name="crew[twitter_name]" className="form-control" disabled={!editable} />
												</div>
											</td>
										</tr>
										</tbody>
									</table>

									{editable && (
										<button
											type="button"
											onClick={async () => {
												// submit form
												await submitForm();
												handleRenameEntry();
											}}
											className="btn btn-primary"
										>Regenerate Crew Names</button>
									)}

									<hr/>
									<h3>Crew</h3>
									<table className="table table-striped">
										<thead>
										<tr>
											{editable && (<th></th>)}
											<th>Position</th>
												{(entry.event.event_types.steer === 1) && (
													<th>
														Steer
													</th>
												)}
												<th width="300">Club</th>
												<th>First Name</th>
												<th>Initials</th>
												<th>Last Name</th>
												{!isPressOffice && (<th>Date of Birth (dd/mm/yyyy)</th>)}
												<th>Has this athlete competed at HRR before, excluding Qualifiers?</th>
											</tr>
										</thead>
										<tbody>
											{Array.from({ length: entry.event.event_types.athlete_qty }, (_, index) =>
												<tr key={index}>
													{editable && (
														<td>
															<button
																type="button"
																onClick={() => handleReorder(index, index - 1, values, setFieldValue)}
																disabled={index === 0}
																className="btn btn-reorder"
															><FontAwesomeIcon icon={faArrowUp}/></button>
															<button
																type="button"
																onClick={() => handleReorder(index, index + 1, values, setFieldValue)}
																disabled={index === values.athletes.length - 1}
																className="btn btn-reorder"
															>
																<FontAwesomeIcon icon={faArrowDown}/>
															</button>
														</td>
													)}

													<td>
														<Field type="text" className="form-control" name={`athletes[${index}].position`} disabled/>
													</td>
													{(entry.event.event_types.steer === 1) && (
														<td>
															<Field type="radio" name="steer" value={index}
															   checked={values.steer === index.toString()}
															   className={(errors.steer && touched.steer) ? 'is-invalid' : ''}
															   disabled={!editable}
															/>
														</td>
													)}
													<td>
														<Field
															component={SelectField}
															name={`athletes[${index}].club`}
															className="form-control"
															options={clubs}
															onChange={(value) => checkClubValue(value, index, setFieldValue)}
															isDisabled={!editable}
														/>
													</td>
													<td>
														<Field type="text" className="form-control" name={`athletes[${index}].first_name`} required disabled={!editable} />
													</td>
													<td>
														<div className="d-flex">
															<div className="px-1">
																<Field type="text" className="form-control" name={`athletes[${index}].initials[0]`} disabled={!editable} />
															</div>
															{(values.athletes?.[index].initials?.[0]?.length > 0) &&
																<div className="px-1">
																	<Field type="text" className="form-control" name={`athletes[${index}].initials[1]`} disabled={!editable}/>
																</div>
															}
															{(values.athletes?.[index]?.initials?.[1]?.length > 0) &&
																<div className="px-1">
																	<Field type="text" className="form-control" name={`athletes[${index}].initials[2]`} disabled={!editable} />
																</div>
															}
														</div>
													</td>
													<td>
														<Field type="text" className="form-control" name={`athletes[${index}].last_name`} required disabled={!editable} />
													</td>
													{!isPressOffice && (<td>
														<Field type="text" className="form-control datepicker-dob" name={`athletes[${index}].dob`} onChange={input => handleDateChange(input, `athletes[${index}].dob`, setFieldValue)} onBlur={input => handleDateBlur(input, `athletes[${index}].dob`, setFieldValue)} required disabled={!editable} />
													</td>)}
													<td>
														<Field component={SelectField} name={`athletes[${index}].previously_rowed`} className="form-control" options={[{ value: 1, label: "Yes" }, { value: 0, label: "No" }]} isDisabled={!editable} />
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div className="row">
									<div className="col-12 form-group py-2 admin-panel">
										<h2>Administration</h2>
										<table className="mb-3">
											{(entry.txn_id && !!editable)  ?
												<tr>
													<td width="150px">Transaction</td>
													<td><Link to={`/admin/orders/${entry.txn_id}`}>Go To Order</Link></td>
												</tr>:null
											}
											<tr>
												<td width="150px">CRI</td>
												<td>
													<Field type="text" name="crew[crew_ranking_index]" className="form-control" disabled={!editable} />
												</td>
											</tr>
											<tr>
												<td width="150px">Entry Status</td>
												<td>
													<Field component={SelectField} name="status" className="form-control" options={statusList} isDisabled={!editable} />
													
													{(values.status === 3) &&
														<>
															<span className="mb-1">Withdrawal Reason</span>
															<Field component="textarea" name="withdrawal_reason" className="form-control" placeholder="Withdrawal reason" disabled={!editable} />
														</>
													}
												</td>
											</tr>
											<tr>
												<td>Event</td>
												<td><Field component={SelectField} name="event" className="form-control" options={
														eventsList.filter((event) => {
															return event.qty === entry.event.event_types.athlete_qty;
														})

												} isDisabled={!editable} /></td>
											</tr>
											<tr>
												<td>Selected</td>
												<td><Field component={SelectField} name="selected" className="form-control" options={selectedList} isDisabled={!editable} /></td>
											</tr>
											<tr>
												<td>Qualification Status</td>
												<td><Field component={SelectField} name="qualification_status" className="form-control" options={qualifiedOptions} isDisabled={!editable} /></td>
											</tr>
											<tr>
												<td>Data checked</td>
												<td><Field type="checkbox" name="data_checked" className="form-check-input" isDisabled={!isAdmin} /></td>
											</tr>
										</table>
										{editable && (
											<>
												<button type="button" className="btn btn-link"
														onClick={() => resendAthleteRegistration()}>Resend Athlete
													Registration Email
												</button>
												<br/>
												<button type="button" className="btn btn-link"
														onClick={() => resendCoachRegistration()}>Resend Coach
													Registration Email
												</button>
											</>
										)}
									</div>
								</div>

								{(getClubData(values.athletes, true).length > 2) && (
									<div className="form-group">
										<h3>Names in Programme</h3>
										<p>Only two clubs shall be displayed in the Daily Racing Programme, any others will be used by the Commentators.</p>
										<div className="row">
											<div className="col-12 col-md-6 form-field">
												<label htmlFor="username">Club 1</label>
												<Field
													component={SelectField}
													name="crew[club_id_1]"
													className="form-control"
													options={selectedClubIdData}
													isDisabled={!editable}
												/>
												<ErrorMessage name="crew[club_id_1]" component="div" className="field-error" />
											</div>

											<div className="col-12 col-md-6 form-field">
												<label htmlFor="password">Club 2</label>
												<Field
													component={SelectField}
													name="crew[club_id_2]"
													className="form-control"
													options={selectedClubIdData}
													isDisabled={!editable}
												/>
												<ErrorMessage name="crew[club_id_2]" component="div" className="field-error" />
											</div>
										</div>
									</div>
								)}

								<div className="row">
									<div className="form-group">
										<div className="row">
											<div className="col-12 form-field">
												<h4>Coach information</h4>
											</div>
											<div className="form-group">
												<label htmlFor="coach">Coach select<span className="required">*</span></label>
												<Select value={values.coach.coach} onChange={input => handleCoachChange(input, setFieldValue, values)} options={coachOptions} />
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 col-md-4 form-field">
												<label htmlFor="coach_first_name">First Name<span className="required">*</span></label>
												<Field type="text" name="coach[first_name]" className="form-control" />
												<ErrorMessage name="coach[first_name]" component="div" className="field-error" />
											</div>

											<div className="col-12 col-md-4 form-field">
												<label htmlFor="coach_middle_initials">Middle
													Initials</label>
												<div className="d-flex mx-n1">
													<div className="px-1">
														<Field type="text" className="form-control"
															   name="coach[initials][0]"/>
														<ErrorMessage name="coach[initials][0]"
																	  component="div"
																	  className="field-error"/>
													</div>
													{(values.coach.initials?.[0]?.length > 0) &&
														<div className="px-1">
															<Field type="text" className="form-control"
																   name="coach[initials][1]"/>
															<ErrorMessage name="coach[initials][1]"
																		  component="div"
																		  className="field-error"/>
														</div>
													}
													{(values.coach.initials?.[1]?.length > 0) &&
														<div className="px-1">
															<Field type="text" className="form-control"
																   name="coach[initials][2]"/>
															<ErrorMessage name="coach[initials][2]"
																		  component="div"
																		  className="field-error"/>
														</div>
													}
												</div>
											</div>

											<div className="col-12 col-md-4 form-field">
												<label htmlFor="coach_last_name">Last Name<span
													className="required">*</span></label>
												<Field type="text" name="coach[last_name]"
													   className="form-control"/>
												<ErrorMessage name="coach[last_name]" component="div"
															  className="field-error"/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 col-md-6 form-field">
												<label htmlFor="coach_email_address">Email<span
													className="required">*</span></label>
												<Field type="email" name="coach[email]"
													   className="form-control"/>
												<ErrorMessage name="coach[email]" component="div"
															  className="field-error"/>
											</div>
											<div className="col-12 col-md-6 form-field">
												<label htmlFor="coach_mobile_number">Mobile Number (including
													country code e.g +44)<span className="required">*</span></label>
												<Field type="text" name="coach[phone]" className="form-control" />
												<ErrorMessage name="coach[phone]" component="div" className="field-error" />
											</div>
										</div>
									</div>

									<div className="col-12 form-group">
										<h3>Supplementary information</h3>
										<Field component="textarea" className="form-control" defaultValue={entry.entry?.supplementary_info} name="supplementary_info" disabled={!editable} />
									</div>

									{(entry.arrival_date) &&
										<div className="col-12 form-group arrival-date">
											<h3>Anticipated arrival date</h3>
											<div className="form-field">
												<label htmlFor="arrival_date">Anticipated arrival date (dd/mm/yyyy)</label>
												<Field type="text" className="form-control datepicker" name="arrival_date" onChange={input => handleDateChange(input, "arrival_date", setFieldValue, "future")} onBlur={input => handleDateBlur(input, "arrival_date", setFieldValue, "future")} defaultValue={entry.entry?.arrival_date} disabled={!editable} />
											</div>
										</div>
									}

									{!isPressOffice && (
										<>
											<div className="col-12 form-group">
												<h3>Crew Emergency Contact</h3>
												<div className="row">
													<div className="col-12 col-md-6 form-field">
														<label htmlFor="emergency_contact">Emergency Contact
															Name</label>
														<Field type="text" className="form-control"
															   name="crew[emergency_contact_name]"
															   defaultValue={entry.crew?.emergency_contact_name}
															   disabled={!editable}/>
													</div>
													<div className="col-12 col-md-6 form-field">
														<label htmlFor="emergency_contact">Emergency Contact Number for
															Crew (including country code e.g +44)</label>
														<Field type="text" className="form-control"
															   name="crew[emergency_contact]"
															   defaultValue={entry.crew?.emergency_contact}
															   disabled={!editable}/>
													</div>
												</div>
											</div>

											<div className="col-12">
												<h3>Declaration</h3>
												{getDeclarationSignings()}
											</div>
										</>
									)}
								</div>
								<div className="form-group form-buttons floating-buttons">
								{editable && (
										<button
											className="btn btn-primary btn-block me-1"
											type="submit"
											disabled={(loading)}
										>
											{loading && (
												<span className="spinner-border spinner-border-sm"></span>
											)}
											<span>Save</span>
										</button>
								)}
									<button
										className="btn btn-secondary btn-block"
										type="button"
										onClick={handlePrintEntry}
									>
										<span>Print PDF</span>
									</button>
								</div>
							</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loading/>;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Entry</h1>
					<div>
						{(entry) && <EventForm/>}
						<hr/>
						<div className="mt-2">
							<h2>Logs</h2>
							<LogTable id={entryId}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(SingleEntry);
