import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookSkull } from '@fortawesome/free-solid-svg-icons';
import {
	globalReset,
	getSettings,
	listBackups,
	restoreBackup,
	doBackup,
	sendCrewCheckInReminder, archiveDraftEntries
} from '../../../api/settings';
import SelectField from '../../../components/forms/select';
import { bulkUpdateStatus } from '../../../api/settings';

const Settings = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');
	const editorRef = useRef(null);

	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState({});
	const [backups, setBackups] = useState({});
	// const [filters, setFilters] = useState({ status: null, selected: null, event: null });

	// useEffect(() => {
	// 	// if (!entries) {
	// 	fetch(`${API_URL}/admin-entries`, {
	// 		headers: {
	// 			// auth url
	// 			'Content-Type': 'application/json',
	// 			'Authorization': `Bearer ${token}`,
	// 		}
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			console.log('data', data);
	// 			setEntries(data);
	// 			// dispatch({ type: 'EVENTS', payload: data });
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// 	// } else {
	// 	// 	setLoading(false);
	// 	// }
	// }, []);

	const initialSettings = async () => {
		try {
			const getAllSettings = await getSettings();
			setSettings(getAllSettings.data);
			setLoading(false);
		} catch (error) {
			console.log('Error getting settings:', error);
		}
	};

	const getBackupData = async () => {
		try {
			const listBackupEntries = await listBackups();
			if (listBackupEntries.data.status === 'success') {
				setBackups(listBackupEntries.data.backups);
			}
		} catch (error) {
			console.log('Error getting backups:', error);
		}
	}

	const updateBulkEntriesStatus = async () => {
		const cnConfirm = window.confirm('Are you sure you want to bulk update entries to Committee Approved?');

		if (!cnConfirm) {
			return;
		}

		const toastId = toast("Updating entry statuses to Committee Approved...", { autoClose: false, type: toast.TYPE.INFO });
		try {
			const getEntriesRes = await bulkUpdateStatus();
			if (getEntriesRes.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry statuses updated", autoClose: 5000 });
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	const crewCheckInReminder = async () => {
		const toastId = toast("Sending crew check in email reminder...", { autoClose: true, type: toast.TYPE.INFO });
		try {
			const emailResponse = await sendCrewCheckInReminder();
			if (emailResponse.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Emails sent", autoClose: 5000 });
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	const archiveDraftWithdrawnEntries = async () => {
		const toastId = toast("Archiving entries...", {autoClose: true, type: toast.TYPE.INFO});
		try {
			const emailResponse = await archiveDraftEntries();
			if (emailResponse.data.status === 'success') {
				toast.update(toastId, {type: toast.TYPE.SUCCESS, render: "Archived entries", autoClose: 5000});
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		initialSettings();
		getBackupData();
	}, []);

	if (loading) {
		return <Loading />;
	}

	const handleDownload = async (values) => {
		const toastId = toast("Creating backup...", { autoClose: false, type: toast.TYPE.INFO });
		try {
			const getNewBackup = await doBackup(values);
			if (getNewBackup.data.status === 'success') {
				const blob = new Blob(
					[Uint8Array.from(atob(getNewBackup.data.backup), c => c.charCodeAt(0))],
					{ type: 'application/csv' }
				);

				const link = document.createElement('a');
				const url = window.URL.createObjectURL(blob);
				link.href = url;
				const date = new Date();
				const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
				if (values.entry_data === true) {
					link.download = `backup-entry-data-${dateString}.sql`;
				} else {
					link.download = `backup-${dateString}.sql`;
				}
				link.click();

				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Backup complete", autoClose: 5000 });

				listBackups();
				// toast("Backup complete", { autoClose: 5000, type: toast.TYPE.SUCCESS });
			}
		} catch (error) {
			toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error downloading backup", autoClose: 5000 });
			console.error('Error downloading backup:', error);
		}
	};

	const setOrphanedRanks = () => {
		const cnConfirm = window.confirm('Are you sure you want to set orphaned crew ranks?');

		if (!cnConfirm) {
			return;
		}

		// toast
		const toastId = toast("Setting orphaned crew ranks...", { autoClose: false, type: toast.TYPE.INFO });

		fetch(`${API_URL}/admin/setranks`, {
			headers: {
				// auth url
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log('data', data);
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Orphaned ranks have been set", autoClose: 5000 });
				setSettings(data);
				setLoading(false);
			})
			.catch((error) => {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error setting orphaned crew ranks", autoClose: 5000 });
				console.error('Error:', error);
			});
	}
	
	const setCrewNumbering = () => {
		const cnConfirm = window.confirm('Are you sure you want to set crew numbering?');

		if (!cnConfirm) {
			return;
		}

		// toast
		const toastId = toast("Setting crew numbering...", { autoClose: false, type: 'info' });

		fetch(`${API_URL}/admin/setcrewnumbering`, {
			headers: {
				// auth url
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log('data', data);
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Crew numbering complete!", autoClose: 5000 });
				// setSettings(data);
				// dispatch({ type: 'EVENTS', payload: data });
				setLoading(false);
			})
			.catch((error) => {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error setting crew numbering", autoClose: 5000 });
				console.error('Error:', error);
			});
	}

	const failNonQualifiers = () => {
		const cnConfirm = window.confirm('Are you sure you want to run the non-qualifier procedure?');

		if (!cnConfirm) {
			return;
		}

		// toast
		const toastId = toast("Running non-qualifier procedure...", { autoClose: false, type: 'info' });

		fetch(`${API_URL}/admin/fail-qualifiers`, {
			headers: {
				// auth url
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log('data', data);
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Non-qualifier procedure complete!", autoClose: 5000 });
				// setSettings(data);
				// dispatch({ type: 'EVENTS', payload: data });
				setLoading(false);
			})
			.catch((error) => {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error running procedure", autoClose: 5000 });
				console.error('Error:', error);
			});
	}

	const resetSystem = async () => {
		const resetConfirm = window.confirm('Are you sure you want to reset the system? This will delete ALL entries, logs, crews and related data.');

		if (!resetConfirm) {
			return;
		}

		// toast
		const toastId = toast("Resetting system...", { autoClose: false, type: 'info' });
		
		try {
			const doReset = await globalReset();
			if (doReset.data.status === 'success') {
				toast.update(toastId, { type: 'success', render: "System reset", autoClose: 5000 });
			} else {
				toast.update(toastId, { type: 'error', render: "Error resetting system", autoClose: 5000 });
			}
		} catch (error) {
			toast.update(toastId, { type: 'error', render: "Error resetting system", autoClose: 5000 });
		}
	};

	const ListBackups = () => {
		if (backups.length === 0) {
			return <p>No backups available</p>;
		}

		const backupList = Object.values(backups).map((backup) => {
			return { value: backup, label: backup }
		});

		return (
			<Formik
				initialValues={{
					backup: '',
				}}
				onSubmit={ async (values, { setSubmitting }) => {
					const toastId = toast("Restoring backup...", { autoClose: false, type: 'info' });

					try {
						const restoreSelectedBackup = await restoreBackup(values);
						if (restoreSelectedBackup.data.status === 'success') {
							toast.update(toastId, { type: 'success', render: "Backup restored", autoClose: 5000 });
						}
					} catch (error) {
						toast.update(toastId, { type: 'error', render: "Error restoring backup", autoClose: 5000 });
					}
				}}
			>
				<Form>
					<Field component={SelectField} name="backup" options={backupList} />
					<button type="submit" className="btn btn-primary mt-2">Restore Backup</button>
				</Form>
			</Formik>
		)
	}

	const settingsForm = () => {
		return (
			<Formik
				initialValues={{
					regatta_date: settings.regatta_date,
					entries_open_date: settings.entries_open_date,
					entries_closed_date: settings.entries_closed_date,
					entries_published_date: settings.entries_published_date,
					admin_email: settings.admin_email,
					finance_email: settings.finance_email,
					entries_open_page: settings.entries_open_page,
					entries_closed_page: settings.entries_closed_page,
					entries_published_page: settings.entries_published_page,
					qualification_general_rules: settings.qualification_general_rules,
				}}
				validationSchema={Yup.object({
					// name: Yup.string()
					// 	.max(15, 'Must be 15 characters or less')
					// 	.required('Required'),
					admin_email: Yup.string().email('Invalid email address').required('Required'),
					finance_email: Yup.string().email('Invalid email address').required('Required'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					const toastId = toast("Saving settings...", { autoClose: false, type: 'info' });

					console.log('values', values);
					// setTimeout(() => {
					// 	alert(JSON.stringify(values, null, 2));
					// 	setSubmitting(false);
					// }, 400);
					fetch(`${API_URL}/admin/settings`, {
						method: 'PUT',
						headers: {
							// auth url
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
						body: JSON.stringify(values),
					})
						.then((response) => response.json())
						.then((data) => {
							console.log('data', data);
							setSettings(data);
							// dispatch({ type: 'EVENTS', payload: data });
							setLoading(false);
							toast.update(toastId, { type: 'success', render: "Settings saved", autoClose: 5000 });
						})
						.catch((error) => {
							console.error('Error:', error);
						});
				}}
			>
				{({ isSubmitting, setFieldValue }) => (
					<Form>
						<div className="row">
							<div className="col-12"><h3>General Settings</h3></div>

							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="name">Next Regatta Date</label>
									<Field name="regatta_date" type="date" className="form-control" />
									<ErrorMessage name="regatta_date" />
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-12 col-md">
										<div className="form-group">
											<label htmlFor="name">Entries Open Date</label>
											<Field name="entries_open_date" type="date" className="form-control" />
											<ErrorMessage name="entries_open_date" />
										</div>
									</div>
									<div className="col-12 col-md">
										<div className="form-group">
											<label htmlFor="name">Entries Closed Date</label>
											<Field name="entries_closed_date" type="date" className="form-control" />
											<ErrorMessage name="entries_closed_date" />
										</div>
									</div>
									<div className="col-12 col-md">
										<div className="form-group">
											<label htmlFor="name">Entries Published Date</label>
											<Field name="entries_published_date" type="date" className="form-control" />
											<ErrorMessage name="entries_published_date" />
										</div>
									</div>
								</div>
							</div>
							<hr/>
							<div className="col-12"><h3>Email Settings</h3></div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="name">Admin Email</label>
									<Field name="admin_email" type="email" className="form-control" />
									<ErrorMessage name="admin_email" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="name">Finance Email</label>
									<Field name="finance_email" type="email" className="form-control" />
									<ErrorMessage name="finance_email"/>
								</div>
							</div>
							<hr/>
							<div className="col-12"><h3>Front Page</h3></div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Entries Open Text</label>
									<Editor
										apiKey='z5pg7f4upagsptp2imknjr9is7x74alrk7ske9p3gwu9neuc'
										onInit={(evt, editor) => editorRef.current = editor}
										onEditorChange={(stringifiedHtmlValue) => {
											setFieldValue("entries_open_page", stringifiedHtmlValue);
										}}
										initialValue={settings.entries_open_page}
										init={{
											height: 500,
											menubar: true,
											plugins: 'code anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker',
											toolbar: 'code | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
										}}
									/>
									<ErrorMessage name="entries_open_page" />
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Entries Closed Text</label>
									<Editor
										apiKey='z5pg7f4upagsptp2imknjr9is7x74alrk7ske9p3gwu9neuc'
										onInit={(evt, editor) => editorRef.current = editor}
										onEditorChange={(stringifiedHtmlValue) => {
											setFieldValue("entries_closed_page", stringifiedHtmlValue);
										}}
										initialValue={settings.entries_closed_page}
										init={{
											height: 500,
											menubar: true,
											plugins: 'code anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker',
											toolbar: 'code | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
										}}
									/>
									<ErrorMessage name="entries_closed_page" />
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Entries Published Text</label>
									<Editor
										apiKey='z5pg7f4upagsptp2imknjr9is7x74alrk7ske9p3gwu9neuc'
										onInit={(evt, editor) => editorRef.current = editor}
										onEditorChange={(stringifiedHtmlValue) => {
											setFieldValue("entries_published_page", stringifiedHtmlValue);
										}}
										initialValue={settings.entries_published_page}
										init={{
											height: 500,
											menubar: true,
											plugins: 'code anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker',
											toolbar: 'code | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
										}}
									/>
									<ErrorMessage name="entries_closed_page" />
								</div>
							</div>
							<hr/>
							<h3>Qualification & General Rules</h3>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Qualification & General Rules Text</label>
									<Editor
										apiKey='z5pg7f4upagsptp2imknjr9is7x74alrk7ske9p3gwu9neuc'
										onInit={(evt, editor) => editorRef.current = editor}
										onEditorChange={(stringifiedHtmlValue) => {
											setFieldValue("qualification_general_rules", stringifiedHtmlValue);
										}}
										initialValue={settings.qualification_general_rules}
										init={{
											height: 500,
											menubar: true,
											plugins: 'code anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker',
											toolbar: 'code | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
										}}
									/>
									<ErrorMessage name="qualification_general_rules" />
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">Save</button>
					</Form>
				)}
			</Formik>
		)
	};

	const TakeBackup = () => {
		return (
			<Formik
				initialValues={{
					name: '',
					entry_data: false,
				}}
				onSubmit={(values, { setSubmitting }) => handleDownload(values)}
			>
				<Form>
					<div className="form-group mb-2">
						<label htmlFor="name">Backup Name</label>
						<Field name="name" type="text" className="form-control" />
					</div>
					<label className="form-check-label">
						<Field type="checkbox" className="form-check-input me-1"  name="entry_data" />
						Backup Entry Data Only
					</label>
					<button type="submit" className="btn btn-primary mt-2" >Take Backup</button>
				</Form>
			</Formik>
		)
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Settings</h1>
				</div>
				<div className="col-12 col-md-6 mb-3"></div>

				<div className="col-12 form-group">
					<div>
						{(settings) &&
							settingsForm()
						}
					</div>
				</div>
				<hr/>
				<div className="col-12 form-group">
					<h2>Crew Settings</h2>
					<div className="d-flex">
						<button className="btn btn-primary" onClick={() => setOrphanedRanks()}>Set Orphaned Ranks</button>
						<button className="btn btn-primary ms-1" onClick={() => setCrewNumbering()}>Run Crew Numbering</button>
						<button className="btn btn-primary ms-1" onClick={() => failNonQualifiers()}>Run Qualifier Failures</button>
						<button className="btn btn-primary ms-1" onClick={() => updateBulkEntriesStatus()}>Bulk Update Entries from Office Approved to Committee Accepted</button>
						<button className="btn btn-primary ms-1" onClick={() => crewCheckInReminder()}>Send crew check-in email reminder</button>
					</div>
				</div>
				<hr/>
				<div className="col-12 form-group">
					<h2>Entry Settings</h2>
					<div className="d-flex">
						<button className="btn btn-primary" onClick={() => archiveDraftWithdrawnEntries()}>Archive Draft & Withdrawn Entries</button>
					</div>
				</div>
				<hr/>
				<div className="col-12 form-group">
					<h2>Backup</h2>
					<div className="row">
						<div className="col-12 col-md-6">
							<h4>Backup</h4>
							<p>Using the button below will create a backup of the database and download it.</p>
							<TakeBackup />
						</div>
						<div className="col-12 col-md-6">
							<h4>Restore Backup</h4>
							<p>Using the dropdown below will restore a backup of the database.</p>
							<ListBackups />
						</div>
					</div>
				</div>
				<hr/>
				<div className="col-12 form-group">
					<h2>System Settings</h2>
					<p>The settings below will result in irreversable system changes. Please take a backup before using these settings.</p>
					<div className="row">
						<div className="col-12 col-md-6">
							<h4>System Reset</h4>
							<p>Using the button below will flush ALL entries, logs and crew compliations.</p>
							<button className="btn btn-danger" onClick={() => resetSystem()}><FontAwesomeIcon icon={faBookSkull} className="me-1" /> RESET SYSTEM</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Settings);
