import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import Pagination from '../../../components/Pagination';
import { getWeighingList } from '../../../api/boattent';
// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';

const BoatTentWeighingList = (props) => {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [page, setPage] = useState(1);
	const [isSearching, setIsSearching] = useState(false);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getWeighingListRes = await getWeighingList(page, sortDirection, sortedColumn);
			if (getWeighingListRes.data.status === 'success') {
				setEntries(getWeighingListRes.data.data);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page, sortDirection, sortedColumn]);

	useEffect(() => {
		console.log('entries', entries);
	}, [entries]);

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setPage(1);
		setEntries(data.data);
	};

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(entries.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				hrr: value.athletes.hrr_number,
				first_name: value.athletes.first_name,
				initials: value.athletes.initials,
				last_name: value.athletes.last_name,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
			</div>
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Cox Weigh-in</h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search
						handleSearch={handleSearch}
						endpoint="admin/weighinglist-search"
						page={page}
						setIsSearching={setIsSearching}
						placeholder="Search crew details"
						setLoading={setLoading} />
				</div>
				<div className="col-12 form-group">
					<div>
						{(entries.data && entries.data.length > 0) ?
							<div>
								<Table
									data={columns()}
									onSortChange={handleSort}
									visibleColumns={[
										{
											name: 'hrr',
											sort: 'hrr'
										},
										{
											name: 'first_name',
											sort: 'first_name'
										},
										{
											name: 'initials',
											sort: 'initials'
										},
										{
											name: 'last_name',
											sort: 'last_name'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="hrr"
									linkLocation="/admin/boat-tent-weigh-in/edit"
								/>
								<Pagination data={entries} setPage={setPage} />
							</div> : <div className="alert alert-danger">No entries found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(BoatTentWeighingList);
