import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import Pagination from '../../../components/Pagination';

// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';

const BoatTent = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		status: [{value:9}],
		qualified_status: [{value:2},{value:3}],
	});
	const [isSearching, setIsSearching] = useState(false);
	const [sortDirection, setSortDirection] = useState('asc');
	const [sortedColumn, setSortedColumn] = useState('id');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};
	// useEffect(() => {
	// 	// if (!entries) {
	// 	fetch(`${API_URL}/admin-entries`, {
	// 		headers: {
	// 			// auth url
	// 			'Content-Type': 'application/json',
	// 			'Authorization': `Bearer ${token}`,
	// 		}
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			console.log('data', data);
	// 			setEntries(data);
	// 			// dispatch({ type: 'EVENTS', payload: data });
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// 	// } else {
	// 	// 	setLoading(false);
	// 	// }
	// }, []);

	const getInitialData = async () => {
		fetch(`${API_URL}/admin/entries?page=${page}&limit=15&status[]=9&qualified_status[]=2&qualified_status[]=3&sorDir=${sortDirection}&sortCol=${sortedColumn}`, {
			headers: {
				// auth url
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log('data', data);
				if (data.status === 'success') {
					setEntries(data.entries);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page, filters, sortedColumn, sortDirection]);

	const handleFilters = (newFilters) => {
		setFilters(newFilters);
	};

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setPage(1);
		setEntries(data);
	};

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(entries.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				crew_number: value.crew.crew_number,
				crew_name: value.crew.crew_name,
				event_name: value.event.name,
				registration: (value.crew.crew_checked_in) ? 'Yes' : 'No',
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
			</div>
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Crew Registrations</h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search
						handleSearch={handleSearch}
						endpoint="admin/entries/search"
						page={page}
						setIsSearching={setIsSearching}
						placeholder="Search crew details"
						setLoading={setLoading}
						externalSearch={true}
					/>
				</div>
				<div className="col-12 form-group">
					<div>
						{(entries.data) ?
							<div>
								<Table
									data={columns()}
									visibleColumns={[
										{
											name: 'crew_number',
											sort: 'crew_number'
										},
										{
											name: 'crew_name',
											sort: 'crew_name'
										},
										{
											name: 'event_name',
											sort: 'event_name'
										},
										{
											name: 'registration',
											sort: 'registration'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="crew_name"
									linkLocation="/admin/boat-tent/edit"
								/>
								<Pagination data={entries} setPage={setPage} />
							</div> : <div className="alert alert-danger">No entries found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(BoatTent);
