import instance from './config';

const getRaceList = () => {
	return instance.get(`/admin/races/`);
};

const saveRaceTimetable = (data) => {
	return instance.post(`/admin/races/`, data);
}

const saveResult = (data) => {
	return instance.post(`/admin/results`, data);
}

const checkResult = (data) => {
	return instance.post(`/admin/results/checkResult`, data);
}

// const getResultsList = () => {
// 	return instance.get(`/admin/results`);
// }

const getResultsList = (day = 'tues') => {
	return instance.get(`/admin/results/${day}`);
};

export {
	checkResult,
	getRaceList,
	saveRaceTimetable,
	saveResult,
	getResultsList
}
