import instance from './config';

const getOrders = (page = 1, sortDir = 'asc', sortCol = 'id') => {
	return instance.get(`/admin/orders?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getOrder = (id) => {
	return instance.get(`/admin/order/${id}`);
};

const updateOrder = (id, values) => {
	return instance.put(`/admin/order/${id}`, values);
}

const refundOrder = (id, orderRefund) => {
	return instance.post(`/admin/order/${id}/refund`, orderRefund);
}

export {
	updateOrder,
	getOrders,
	getOrder,
	refundOrder
}
