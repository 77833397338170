import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import * as Yup from "yup";
import {Formik, Form, Field, ErrorMessage} from "formik";
import { toast } from 'react-toastify';
import {getCommentatorCrew, getCommentatorPrintDoc} from '../../../api/commentators';
import LogTable from '../../../components/LogTable';
import {useSelector} from "react-redux";
import {checkRole} from "../../../utils/Helpers";

const CommentatorSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	// get the entry id from the url
	const entryId = props.router.params.id;

	// get ?race_id= from url
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const raceId = searchParams.get('race_id');
	const station = searchParams.get('station');

	const [loading, setLoading] = useState(true);
	const [entry, setEntry] = useState({});
	const [race, setRace] = useState({});

	const days = {
		"Tue": '#ed9a9a',
		"Weds": '#bc9bff',
		"Thurs": '#c5e0b3',
		"Fri": '#a8abaa',
		"Sat": '#FCE599',
		"Sun": '#BDD7EE',
	};

	const user = useSelector(state => state.user);
	const isPressOffice = checkRole(user.user.role, 'press-office', true);

	const getInitialData = async () => {
		let params = '';
		if (raceId && station) {
			params = 'race_id=' + raceId + '&station=' + station;
		}

		try {
			const response = await getCommentatorCrew(entryId, params);
			if (response.data.status === "success") {
				setEntry(response.data.entry);
				setRace(response.data.race);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getCommentatorCrewPrintPDF = async () => {
		const toastId = toast("Generating report...", { autoClose: false, type: 'info' });
		try {
			let params = '';
			if (raceId && station) {
				params = 'station=' + station;
			}

			const getCommentatorPrint = await getCommentatorPrintDoc(raceId, params);

			if (getCommentatorPrint.data.status === 'success') {
				const blob = new Blob(
					[Uint8Array.from(atob(getCommentatorPrint.data.report), c => c.charCodeAt(0))],
					{ type: 'application/pdf' }
				);

				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'race-'+race?.day+'-'+race?.race+'.pdf';
				link.click();

				toast.update(toastId, { render: "Downloading Report", type: 'success', autoClose: 5000 });
			}
		} catch (error) {
			toast.update(toastId, { render: "Unable to download report", type: 'error', autoClose: 5000 });
		}
	}

	useEffect(() => {
		getInitialData()
	}, []);


	const commentatorForm = () => {
		const initialValues = {
			athlete: entry.crew?.crew_members?.reduce((acc, item) => {
				if (item.athletes ) {
					const athleteId = item.athletes.id;

					return {
						...acc,
						[athleteId]: {
							sporting_highlights: item.athletes?.sporting_highlights,
							job_course_university: item.athletes?.job_course_university,
							other_info: item.athletes?.other_info,
						},
					};
				}

				return acc;
			}, {}),
			coach_bio: entry.crew?.coach?.bio,
			coach_history: entry.crew?.coach?.coaching_history,
			coach_ethos: entry.crew?.coach?.coaching_ethos,
			coach_additional_info: entry.crew?.coach?.coaching_additional_info,
			supplementary_info: entry.supplementary_info,
			commentator_nuggets: entry.commentator_nuggets,
			commentators: entry.crew.commentator_notes,
			club_info: entry.crew.crew_members.reduce((acc, item) => {
				if (!acc[item.club.id]) {
					acc[item.club.id] = item.club?.notes;
				}
				return acc;
			}, {})
		};

		const validationSchema = Yup.object().shape({
			athlete: Yup.object(
				Object.values(entry.crew?.crew_members || {}).reduce((acc, item) => {
					acc[item.athletes.id] = Yup.object().shape({
						sporting_highlights: Yup.string()
							.max(300).label('Sporting Highlights'),
						job_course_university: Yup.string()
							.max(120).label('Job/Education'),
						other_info: Yup.string()
							.max(300).label('Further Info'),
					});
					return acc;
				}, {})
			),
			coach_bio: Yup.string().notRequired().max(250).label('Coach Bio'),
			coach_history: Yup.string().notRequired().max(250).label('Coach History'),
			coach_ethos: Yup.string().notRequired().max(250).label('Coach Ethos'),
			coach_additional_info: Yup.string().notRequired().max(250).label('Coach Additional Info'),
			supplementary_info: Yup.string().notRequired(),
			commentator_nuggets: Yup.string().notRequired().max(250).label('Commentator Nuggets'),
			club_info: Yup.object(
				Object.values(entry.crew?.crew_members || {}).reduce((acc, item) => {
					const clubId = item.club.id;
					if (!acc[clubId]) {
						acc[clubId] = Yup.string().max(300).label('Club Info');
					}
					return acc;
				}, {})
			),
			commentators: Yup.object().shape(
				Object.keys(days).reduce((acc, day) => {
					acc[day.toLowerCase()] = Yup.string()
						.max(250, `${day} must be 250 characters or less`);
					return acc;
				}, {})
			),
		});

		const onSubmit = (values, { setSubmitting }) => {
			// add toast
			const toastId = toast("Saving...", { autoClose: false });
			setSubmitting(true);

			fetch(`${API_URL}/admin/commentators/crew/${entry.id}`, {
				method: 'PUT',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log('Success:', data);
					setLoading(false);
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Saved!", autoClose: 5000 });
				})
				.catch((error) => {
					console.error('Error:', error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid }) => {
							return (
								<Form>
									<div>
										<table className="table table-striped align-middle">
											<thead>
											<tr>
												<th>Pos</th>
												<th>Name</th>
												<th>Age</th>
												<th>Prev HRRs</th>
												<th>Highlights - Job/Education - Further Info</th>
											</tr>
											</thead>
											<tbody>
											{Object.values(entry.crew.crew_members).map((itm, ind) => {
												return (
													<tr key={ind}>
														<td>
															{itm.position}
															{itm.steer && (
																<> <span>(Steer)</span></>
															)}
														</td>
														<td>
															{itm.athletes?.first_name} {itm.athletes?.last_name}
														</td>
														<td>
															{itm.athletes?.age}
														</td>
														<td>
															{/* {itm.athletes.previously_rowed} */}
														</td>
														<td>
															<Field name={`athlete[${itm.athletes?.id}].sporting_highlights`} component="textarea" className="form-control" disabled={isPressOffice} />
															<ErrorMessage name={`athlete[${itm.athletes?.id}].sporting_highlights`} component="div" className="field-error" />

															<Field name={`athlete[${itm.athletes?.id}].job_course_university`} component="textarea" className="form-control" disabled={isPressOffice} />
															<ErrorMessage name={`athlete[${itm.athletes?.id}].job_course_university`} component="div" className="field-error" />

															<Field name={`athlete[${itm.athletes?.id}].other_info`} component="textarea" className="form-control" disabled={isPressOffice} />
															<ErrorMessage name={`athlete[${itm.athletes?.id}].other_info`} component="div" className="field-error" />
														</td>
													</tr>
												)
											})}
											</tbody>
										</table>
										<table className="table align-middle">
											<tbody>
											<tr>
												<td width="200px">
													Coach Name
												</td>
												<td>
													{(entry.crew?.coach?.user_id) ? entry.crew?.coach?.user?.first_name + ' ' + entry.crew?.coach?.user?.last_name : entry.crew?.coach?.first_name + ' ' + entry.crew?.coach?.last_name}
												</td>
											</tr>
											<tr>
												<td>
													Coach Bio
												</td>
												<td>
													<Field name="coach_bio" component="textarea" className="form-control" disabled={isPressOffice} />
													<ErrorMessage name="coach_bio"  component="div" className="field-error" />
												</td>
											</tr>
											<tr>
												<td>
													Coach History
												</td>
												<td>
													<Field name="coach_history" component="textarea" className="form-control" disabled={isPressOffice} />
													<ErrorMessage name="coach_history"  component="div" className="field-error" />
												</td>
											</tr>
											<tr>
												<td>
													Coach Ethos
												</td>
												<td>
													<Field name="coach_ethos" component="textarea" className="form-control" disabled={isPressOffice} />
													<ErrorMessage name="coach_ethos"  component="div" className="field-error" />
												</td>
											</tr>
											<tr>
												<td>
													Coach Additional Info
												</td>
												<td>
													<Field name="coach_additional_info" component="textarea" className="form-control" disabled={isPressOffice} />
													<ErrorMessage name="coach_additional_info"  component="div" className="field-error" />
												</td>
											</tr>
											<tr>
												<td>
													Supplementary Info
												</td>
												<td>
													<Field name="supplementary_info" component="textarea" className="form-control" disabled={isPressOffice} />
												</td>
											</tr>
											<tr>
												<td>
													Commentator Nuggets
												</td>
												<td>
													<Field name="commentator_nuggets" component="textarea" className="form-control" disabled={isPressOffice} />
													<ErrorMessage name="commentator_nuggets"  component="div" className="field-error" />
												</td>
											</tr>
											{entry.crew?.crew_members && (
												<tr>
													<td>Club Info</td>
													<td>
														{Object.values(entry.crew?.crew_members.reduce((acc, item) => {
															// remove duplicate clubs
															if (!acc[item?.club?.id]) {
																acc[item.club.id] = item.club;
															}
															return acc;
														}, {})).map((club) => (
																<div key={club?.id} className="mb-1">
																	<label htmlFor={`club_info[${club?.id}]`}>{club?.name}</label>
																	<Field
																		name={`club_info[${club?.id}]`}
																		component="textarea"
																		className="form-control"
																		disabled
																	/>
																	<ErrorMessage
																		name={`club_info[${club?.id}]`}
																		component="div"
																		className="field-error"
																	/>
																</div>
															))}
													</td>
												</tr>
											)}
											{Object.entries(days).map((day, index) => {
												const dayLower = day[0].toLowerCase();
												return (
													<tr key={index}>
														<td style={{ backgroundColor: day[1] }}>
															{day[0]}
														</td>
														<td>
															<Field name={`commentators[${dayLower}]`} component="textarea" className="form-control" disabled={isPressOffice}/>
															<ErrorMessage name={`commentators[${dayLower}]`}  component="div" className="field-error" />
														</td>
													</tr>
												)
											})}
											</tbody>
										</table>
									</div>
									<div className="form-group d-flex gap-1">
									{!isPressOffice && <div className="form-buttons">
											<button
												className="btn btn-primary btn-block"
												type="submit"
												disabled={(loading)}
											>
												{loading && (
													<span className="spinner-border spinner-border-sm"></span>
												)}
												<span>Save</span>
											</button>
										</div>
									}
										<button className="btn btn-secondary" onClick={getCommentatorCrewPrintPDF} type="button">
											Print
										</button>
									</div>
								</Form>
						)}}
					</Formik>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loading/>;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-12">
					<table className="table table-borderless align-middle">
						<tbody>
						<tr>
							<td>{race?.day}</td>
								<td></td>
								<td>{race?.race}</td>
								<td className="text-center"><h3>{entry.event.name}, {entry.event.event_code}</h3></td>
								<td className="text-end">{race?.start_time}</td>
							</tr>
							<tr>
								<td>{(race?.berks_id === entryId) ? <span style="color:#1E6600">2, Bucks</span> :<span style={{color: '#C00000'}}>1, Berks</span>}</td>
								<td style={{ color: '#1E14FF' }}>{(entry.qualification_status === 1) ? 'Q' : (entry.qualification_status === 2) ? 'T' : ''}</td>
								<td style={{ color: '#1E14FF' }}>{entry.crew.crew_number}</td>
								<td className="text-center"><h3 style={{ color: '#1E14FF' }}>{(entry.selected && entry.selected !== "0") ? <i>{entry.crew.full_name_country} ({entry.crew.crew_number})</i> : <>{entry.crew.full_name_country} ({entry.crew.crew_number})</>}</h3></td>
								<td className="text-end">{entry.crew?.crew_members[0]?.athletes?.club?.colours}</td>
							</tr>
						</tbody>
					</table>
					{(entry) && commentatorForm()}
					{(entry && entry.id) ? <LogTable entryId={entry.id} /> : null}
				</div>
			</div>
		</div>
	);
}

export default withRouter(CommentatorSingle);
