import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faRefresh, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import generateSecureRandomPassword from '../../../utils/pwGen';
import { adminGetUser } from '../../../api/user';

const UserSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);

	// get user from store
	const thisUser = useSelector((state) => state.user);

	const getInitialData = (id) => {
		(async () => {
			const response = await adminGetUser(id);
			if (response.data.status === "success") {
				setUser(response.data.user);
				setLoading(false);
			}
		})();
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;
			if (id) {
				setLoading(true);
				getInitialData(id);
			} else {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}, []);

	const permissionLevels = () => {
		// if user is admin, show all permission levels
		if (thisUser && thisUser.user.role && thisUser.user.role.includes('admin')) {
			return [
				{ value: 'admin', label: 'Admin' },
				{ value: 'regatta-admin', label: 'Regatta Admin' },
				{ value: 'commentator', label: 'Commentator' },
				{ value: 'press-office', label: 'Press office' },
				{ value: 'boattent', label: 'Boat Tent' },
				{ value: 'athlete', label: 'Athlete' },
				{ value: 'coach', label: 'Coach' },
				{ value: 'user', label: 'User' },
			];
		} else if (thisUser && thisUser.user.role && thisUser.user.role.includes('regatta-admin')) {
			return [
				{ value: 'regatta-admin', label: 'Regatta Admin' },
				{ value: 'commentator', label: 'Commentator' },
				{ value: 'boattent', label: 'Boat Tent' },
				{ value: 'athlete', label: 'Athlete' },
				{ value: 'coach', label: 'Coach' },
				{ value: 'user', label: 'User' },
			];
		} else {
			return [
				{ value: 'user', label: 'User' },
			];
		}
	}

	const UserForm = () => {
		// if entry is an object
		const roles = (user) ? JSON.parse(user.role):['user'];

		const initialValues = {
			blocked: (user && user.blocked) ? user.blocked : false,
			first_name: (user && user.first_name) ? user.first_name : '',
			last_name: (user && user.last_name) ? user.last_name : '',
			email: (user && user.email) ? user.email : '',
			role: (user && roles) ? JSON.parse(user.role) : ['user'],
			password: '',
			blocked: (user && user.blocked) ? true : false,
			validated: (user && user.validated) ? true : false,
		};

		const validationSchema = Yup.object().shape({
			first_name: Yup.string().required('Required'),
			last_name: Yup.string().required('Required'),
			email: Yup.string().email('Invalid email').required('Required'),
			// role: Yup.string().required('Required'),
			password: Yup.string().min(8, 'Password must be at least 8 characters').notRequired(),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			const endpoint = (data.id) ? `${API_URL}/admin/user/${data.id}` : `${API_URL}/admin/user`;
			fetch(endpoint, {
				method: (data.id) ? 'PUT' : 'POST',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((res) => {
					if (res) {
						setLoading(false);
						setSubmitting(false);
						toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "User saved successfully!", autoClose: 5000 });

						if (res.id && !data.id) {
							window.location.href = `/admin/users`;
						}
					}
				})
				.catch((error) => {
					console.error(error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting, errors }) => {
							// check errors
							// console.log('errors', errors);
							// const eventType = (values.event_type) ? eventTypes.find((item) => item.id === values.event_type) : null;
							return (
								<Form autoComplete='false'>
									<div className="row">
										<div className="col-12 col-md-6 form-group-flex">
											<label htmlFor="first_name">First Name:</label>
											<Field type="text" className="form-control" name="first_name" />
											<ErrorMessage name="first_name" component="div" className="field-error" />
										</div>
										<div className="col-12 col-md-6 form-group-flex">
											<label htmlFor="last_name">Last Name:</label>
											<Field type="text" className="form-control" name="last_name" />
											<ErrorMessage name="last_name" component="div" className="field-error" />
										</div>
									</div>
									<div className="form-group-flex">
										<label htmlFor="email">Email:</label>
										<Field type="email" name="email" className="form-control" />
										<ErrorMessage name="email" component="div" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="email">Password: <button className="btn btn-link" type="button" onClick={() => {
											// generate a random password with 16 characters and special characters
											const randomPassword = generateSecureRandomPassword(32);
											setFieldValue('password', randomPassword);
										}}><FontAwesomeIcon icon={faRefresh} /></button></label>
										<Field type="text" name="password" className="form-control" />
										<ErrorMessage name="password" component="div" className="field-error" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="email">Role/s:</label>
										<Field component={SelectField} name="role" options={permissionLevels()} isMulti={true} className="form-control" />
										<ErrorMessage name="role" component="div" className="field-error" />
									</div>
									<hr/>
									<div className="row">
										<div className="col-6">
											<div className="form-check">
												<Field type="checkbox" name="blocked" className="form-check-input" />
												<label className="form-check-label" htmlFor="flexCheckDefault">
													Block User
													<ErrorMessage name="blocked" component="div" className="field-error" />
												</label>
											</div>
										</div>
										<div className="col-6">
											<div className="form-check">
												<Field type="checkbox" name="validated" className="form-check-input" />
												<label className="form-check-label" htmlFor="flexCheckDefault">
													Validate User Email
													<ErrorMessage name="validated" component="div" className="field-error" />
												</label>
											</div>
										</div>
									</div>

									<hr />

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin />
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}

	
	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/users"><FontAwesomeIcon icon={faArrowLeft} /> Back to Users</Link></div>
				<div className="col-12 col-md-6">
					{(user && user.id) ? <h1>Edit User</h1>:<h1>Add User</h1>}
				</div>

				<div className="col-12 form-group">
					{<UserForm />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(UserSingle);
