import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {Link} from "react-router-dom";
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import * as Yup from "yup";
import {refundOrder} from "../../api/orders";
import {emailSend} from "../../api/triggers";
import moment from "moment/moment";

const OrderItems = (props) => {
	const [isRefundPanelVisible, setIsRefundPanelVisible] = useState(false);
	const [orderItemsRefunded, setOrderItemsRefunded] = useState([]);

	let initialValues = {
		order_item_refunds: [],
		refund_date: '',
		refund_reason: '',
	}

	const validationSchema = Yup.object({
		order_item_refunds: Yup.array(),
		refund_date: Yup.date()
			.max(new Date(), 'Refund date must be today or earlier')
			.required('Refund date is required'),
	});

	useEffect(() => {
		const orderItemsRefunded = props.order.items.filter((item) => {
			return item.refund_amount !== undefined && item.refund_amount > 0;
		});
		setOrderItemsRefunded(orderItemsRefunded);
	}, [])

	const resendNGBAuth = (entryId) => {
		const toastId = toast("Resending NGB authorisation email...", {autoClose: false});

		(async () => {
			const sendEmail = await emailSend(entryId, 'ngb_resend');
			if (sendEmail.data.status === 'success') {
				toast.update(toastId, {
					type: toast.TYPE.SUCCESS,
					render: "NGB authorisation email sent",
					autoClose: 5000
				});
			} else {
				toast.update(toastId, {type: toast.TYPE.ERROR, render: "Error sending email", autoClose: 5000});
			}
		})();
	};

	const handleOrderItemRefunds = async (values, actions) => {
		if (values.order_item_refunds.length === 0) {
			toast.warn("Please select at least one item to refund.");
			return;
		}

		const toastId = toast("Refunding order...", {autoClose: false});

		try {
			const response = await refundOrder(props?.order.id, values);

			if (response.data.refund_success) {
				setOrderItemsRefunded(prevState => [...prevState, ...response.data.refund_success]);
			}

			if (response.data.status === 'success') {
				toast.update(toastId, {type: toast.TYPE.SUCCESS, render: "Order items refunded", autoClose: 5000});

				// close refund panel and reset form if all refunds are successful
				setIsRefundPanelVisible(false);
				actions.resetForm();
			}
			else if (response.data.status === 'partial_success') {
				toast.update(toastId, {type: toast.TYPE.WARNING, render: "Some order items were refunded, but others could not be processed.", autoClose: 5000});

				const orderItemsRequiringAction = values.order_item_refunds.filter(itemId => {
					return !response.data.refund_success.map((item) => item.id).includes(itemId);
				});

				actions.setValues({
					order_item_refunds: orderItemsRequiringAction,
					refund_date: values.refund_date,
					refund_reason: values.refund_reason
				});
			}
			else {
				toast.update(toastId, {type: toast.TYPE.ERROR, render: "Error refunding order items", autoClose: 5000});
			}
		} catch (error) {
			console.log('errr', error);
			toast.update(toastId, {type: toast.TYPE.ERROR, render: "Error refunding order items", autoClose: 5000});
		}
	}

	const formatRefundDate = (refundDate) => {
		return moment(refundDate).format('Do MMMM YYYY');
	}

	const allOrderItemsAreRefunded = () => {
		return orderItemsRefunded.length === props.order.items.length;
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, actions) => handleOrderItemRefunds(values, actions)}
		>
			{({values, dirty}) => (
				<Form>
					<div className="col-12">
						<h3>Order Items</h3>
						<table className="table table-striped">
							<thead>
							<tr>
								<th>Item</th>
								<th>Price</th>
								{isRefundPanelVisible && (
									<th>Refund</th>
								)}
							</tr>
							</thead>
							<tbody>
							<FieldArray
								name="order_item_refunds"
								render={(arrayHelpers) => (
									<>
										{props?.order?.items.map((item, index) => {
											const refundedOrderItem = orderItemsRefunded.find(refundedItem => refundedItem?.id === item?.id);
											return (
												<tr key={index}>
													<td>
														<small>{item.entry.event.name}</small>
														<br/>
														<Link to={`/admin/entries/edit/${item.entry.id}`}>
															{item.entry.crew.crew_name}
														</Link>
														<div className="d-block mt-2">
															<button
																className="btn btn-link"
																type="button"
																onClick={() => resendNGBAuth(item.entry.id)}
															>
																Resend Athlete NGB Auth
															</button>
														</div>
													</td>
													<td>
														£{item.amount.toFixed(2)}

														{refundedOrderItem && refundedOrderItem?.refund_amount && (
															<p>Refunded on {formatRefundDate(refundedOrderItem.refund_date)}</p>
														)}
														{refundedOrderItem && refundedOrderItem?.refund_reason && (
															<p>Reason: {refundedOrderItem.refund_reason}</p>
														)}
													</td>
													<td>
														{isRefundPanelVisible && !refundedOrderItem && (
															<>
																<div className="form-field form-check mb-2">
																	<input
																		type="checkbox"
																		className="form-check-input"
																		id={`refund_${item.id}`}
																		checked={arrayHelpers.form.values.order_item_refunds.includes(item.id)}
																		onChange={(e) => {
																			if (e.target.checked) {
																				arrayHelpers.push(item.id);
																			} else {
																				const idx = arrayHelpers.form.values.order_item_refunds.indexOf(
																					item.id
																				);
																				arrayHelpers.remove(idx);
																			}
																		}}
																	/>
																	<label
																		htmlFor={`refund_${item.id}`}
																		className="form-check-label"
																	>
																		Refund
																	</label>
																</div>
															</>
														)}
													</td>
												</tr>
											)
										})}
									</>
								)}
							/>
							</tbody>
							<tfoot>
							<tr>
								<td colSpan={!isRefundPanelVisible ? 3 : 2}>
									{!allOrderItemsAreRefunded() && (
										<button className="btn btn-outline-primary"
												type="button"
												onClick={() => setIsRefundPanelVisible(!isRefundPanelVisible)}>
											{isRefundPanelVisible ? 'Cancel' : 'Refund'}
										</button>
									)}
								</td>
								<td>
									{isRefundPanelVisible && (
										<>
											<div className="mb-1">
												<label htmlFor="refund_date">Reason date</label>
												<Field type="date"
													   name="refund_date"
													   className="form-control"
													   max={new Date().toISOString().split('T')[0]}
												/>
												<ErrorMessage name="refund_date"/>
											</div>
											<div className="mb-1">
												<label htmlFor="refund_reason">Reason for refund (optional)</label>
												<Field component="textarea" className="form-control"
													   name="refund_reason"/>
												<div className="d-block mt-2">
													<button className="btn btn-primary" type="submit">
														Refund
													</button>
												</div>
											</div>
										</>
									)}
								</td>
							</tr>
							</tfoot>
						</table>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default OrderItems;
