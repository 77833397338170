import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

const Modal = ({ title, children, onSave, onClose, show, setShow, closeOnBackdropClick = true, hideButtons = true }) => {
	const handleClose = () => {
		setShow(false);
		if (onClose) onClose();
	};

	return (
		<>
			{show && (
				<div
					className="modal fade show d-block"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="modalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="modalLabel">{title}</h5>
								<button
									type="button"
									onClick={handleClose}
									aria-label="Close"
								>
									<FontAwesomeIcon icon={faClose} />
								</button>
							</div>
							<div className="modal-body">{children}</div>
							{!hideButtons && (
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={handleClose}>
										Close
									</button>
									<button type="button" className="btn btn-primary" onClick={onSave}>
										Save changes
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			{show && closeOnBackdropClick && (
				<div className="modal-backdrop fade show" onClick={handleClose}></div>
			)}
		</>
	);
};

Modal.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	onSave: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	closeOnBackdropClick: PropTypes.bool,
};

Modal.defaultProps = {
	title: 'Modal Title',
	onSave: () => {},
	onClose: () => {},
	closeOnBackdropClick: true,
};

export default Modal;
