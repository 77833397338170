import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faCheckCircle, faSpinner, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {getAthlete, getAthletes, saveAthlete} from '../../../api/athletes';
import { getClubs } from '../../../api/clubs';
import { saveAthleteHistory, deleteAthleteHistory } from '../../../api/athletes';
import AsyncSelect from "react-select/async";

const AthleteSingle = (props) => {
	const [loading, setLoading] = useState(true);
	const [athlete, setAthlete] = useState(null);
	const [clubs, setClubs] = useState(null);
	const [siblings, setSiblings] = useState([]);

	const events = useSelector(state => state.events);

	// sort events into array of objects for select field
	const eventTypeList = Object.values(events.events).map((event) => {
		return {
			label: event.name,
			value: event.id,
		}
	});

	const getClubsList = async () => {
		try {
			const getAllClubsList = await getClubs();
			if (getAllClubsList.data.status === 'success') {
				getAllClubsList.data.clubs.forEach((item) => {
					item.label = item.name;
					item.value = item.id;
				});

				setClubs(getAllClubsList.data.clubs);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const loadAthleteOptions = (inputValue, callback) => {
		if (!inputValue) {
			callback([]);
			return;
		}

		const filter = {
			hrr_number: inputValue,
			excludeId: athlete?.id,
		}

		getAthletes(1, 'asc', 'id', filter).then((athletes) => {
			if(athletes.data.status !== 'success') {
				callback([]);
				console.error('Error fetching athletes');
			}

			const formattedOptions = formatAthletesForSelect(athletes?.data?.athletes?.data);

			callback(formattedOptions);
		});
	};

	const onChange = (newValue, actionMeta) => {
		switch (actionMeta.action) {
			case 'select-option':
			case 'remove-value':
				setSiblings(newValue);
				break;
			case 'clear':
				setSiblings([]);
				break;
			default:
				console.log('Unhandled action:', actionMeta.action);
		}
	};

	const getInitialData = async (id) => {
		if (!clubs) {
			getClubsList();
		}

		if (!athlete) {
			const getAthleteData = await getAthlete(id);
			if (getAthleteData.data.status === 'success') {
				setAthlete(getAthleteData.data.athlete);

				if(!getAthleteData.data.athlete.siblings) {
					return;
				}

				const formattedSiblings = formatAthletesForSelect(getAthleteData?.data?.athlete?.siblings);
				setSiblings(formattedSiblings);
			}
		}
	}

	const formatAthletesForSelect = (athletes) => {
		return athletes.map((athlete) => ({
			value: athlete.id,
			label: `${athlete.hrr_number} - ${athlete.first_name} ${athlete.last_name}`,
		})) ?? [];
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				// setLoading(true);
				getInitialData(id);
			}
		} else {
			getClubsList();
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (athlete && clubs) {
			setLoading(false);
		}
	}, [athlete, clubs]);

	const formatSiblings = (siblings) => {
		return siblings.map((sibling) => sibling.value) ?? [];
	}

	const AthleteForm = () => {
		const initialValues = {
			hrr_number: (athlete && athlete.hrr_number) ? athlete.hrr_number : '',
			first_name: (athlete && athlete.first_name) ? athlete.first_name : '',
			last_name: (athlete && athlete.last_name) ? athlete.last_name : '',
			initials: {
				0: (athlete && athlete.initial_parts?.[0]) ? athlete.initial_parts?.[0] : '',
				1: (athlete && athlete.initial_parts?.[1]) ? athlete.initial_parts?.[1] : '',
				2: (athlete && athlete.initial_parts?.[2]) ? athlete.initial_parts?.[2] : '',
			},
			dob: (athlete && athlete.dob) ? athlete.dob : '',
			colours: (athlete && athlete.colours) ? athlete.colours : '',
			address_1: (athlete && athlete.address_1) ? athlete.address_1 : '',
			address_2: (athlete && athlete.address_2) ? athlete.address_2 : '',
			postcode_zip: (athlete && athlete.postcode_zip) ? athlete.postcode_zip : '',
			// contact
			contact: (athlete && athlete.contact) ? athlete.contact : '',
			tel_day: (athlete && athlete.tel_day) ? athlete.tel_day : '',
			tel_eve: (athlete && athlete.tel_eve) ? athlete.tel_eve : '',
			mobile: (athlete && athlete.mobile) ? athlete.mobile : '',
			email: (athlete && athlete.user?.email) ? athlete.user?.email : '',
			// secondary_email: (athlete && athlete.secondary_email) ? athlete.secondary_email : '',
			club_id: (athlete && athlete.club_id) ? athlete.club_id : '',
			// misc
			notes: (athlete && athlete.notes) ? athlete.notes : '',
			hold: (athlete && athlete.hold === "1") ? true : false,
			sporting_highlights: (athlete && athlete.sporting_highlights) ? athlete.sporting_highlights : '',
			job_course_university: (athlete && athlete.job_course_university) ? athlete.job_course_university : '',
			other_info: (athlete && athlete.other_info) ? athlete.other_info : '',
			weight: (athlete && athlete.weight) ? athlete.weight : '',
			weight_stone: (athlete && athlete.weight) ? (parseFloat(athlete.weight) / 6.35).toFixed(2) : '',
			siblings: siblings,
		};

		const validationSchema = Yup.object().shape({
			first_name: Yup.string().required('Required'),
			last_name: Yup.string().required('Required'),
			dob: Yup.string().required('Required'),
			email: Yup.string().notRequired(),
			club_id: Yup.string().required('Required'),
			sporting_highlights: Yup.string().notRequired(),
			job_course_university: Yup.string().notRequired(),
			other_info: Yup.string().notRequired(),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });



			const data = {
				...values,
				siblings: formatSiblings(values.siblings),
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			(async () => {
				const saveTheAthlete = await saveAthlete(data);
				if (saveTheAthlete.data.status === 'success') {
					setLoading(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Athlete saved successfully!", autoClose: 5000 });
					if (saveTheAthlete.data.athlete.id) {
						window.location.href = `/admin/athletes/edit/${saveTheAthlete.data.athlete.id}`;
					}
				} else {
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				}
			})();
		};


		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting, handleChange }) => {
							return (
								<Form>
									{(athlete && athlete.hrr_number) &&
										<div className="form-group-flex">
											<label htmlFor="username">HRR Number:</label>
											<Field type="text" className="form-control" name="hrr_number"
												   disabled="disabled"/>
										</div>
									}
									<div className="row">
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="username">First Name:</label>
												<Field type="text" className="form-control" name="first_name"/>
												<ErrorMessage name="first_name" component="div"
															  className="field-error"/>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<label htmlFor="username">Initials Name:</label>
											<div className="d-flex">
												<div className="px-1">
													<Field type="text" className="form-control" name="initials[0]"/>
													<ErrorMessage name="initials[0]" component="div"
																  className="field-error"/>
												</div>
												{(values.initials?.[0]?.length > 0) &&
													<div className="px-1">
														<Field type="text" className="form-control" name="initials[1]"/>
														<ErrorMessage name="initials[1]" component="div"
																	  className="field-error"/>
													</div>
												}
												{(values.initials?.[1]?.length > 0) &&
													<div className="px-1">
														<Field type="text" className="form-control" name="initials[2]"/>
														<ErrorMessage name="initials[2]" component="div"
																	  className="field-error"/>
													</div>
												}
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="username">Last Name:</label>
												<Field type="text" className="form-control" name="last_name"/>
												<ErrorMessage name="last_name" component="div" className="field-error"/>
											</div>
										</div>
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Date of Birth</label>
										<Field type="text" name="dob" className="form-control"/>
										<ErrorMessage name="dob" component="div" className="field-error"/>
									</div>
									<div className="form-group-flex">
										<label htmlFor="siblings">Twins</label>
										<AsyncSelect
											value={siblings}
											loadOptions={loadAthleteOptions}
											defaultOptions
											onChange={onChange}
											placeholder="Start typing athlete HRR number..."
											isMulti
										/>
										<ErrorMessage name="siblings" component="div" className="field-error"/>
									</div>

									<div className="form-group-flex">
										<label htmlFor="event_code">Club</label>
										<Field component={SelectField} name="club_id" className="form-control"
											   options={clubs}/>
										<ErrorMessage name="club_id" component="div" className="field-error"/>
									</div>

									{(athlete && athlete.user?.email) &&
										<div className="form-group-flex">
											<label htmlFor="event_code">Email</label>
											<Field type="text" name="email" className="form-control"/>
											<ErrorMessage name="email" component="div" className="field-error"/>
										</div>
									}

									{(athlete && athlete.cox) &&
										<div className="row">
											<div className="col-12 col-md-6">
												<label htmlFor="event_code">Cox Weight (kg)</label>
												<Field
													type="number"
													name="weight"
													className="form-control"
													onChange={(e) => {
														handleChange(e); // Handle Formik's default onChange behavior
														const kg = e.target.value;
														const stones = kg / 6.35;
														setFieldValue('weight_stone', stones.toFixed(2)); // Convert and update the other field
													}}
												/>
												<ErrorMessage name="weight" component="div" className="field-error"/>
											</div>
											<div className="col-12 col-md-6">
												<label htmlFor="event_code">Cox Weight (st)</label>
												<Field
													type="number"
													name="weight_stone"
													className="form-control"
													onChange={(e) => {
														handleChange(e); // Handle Formik's default onChange behavior
														const stones = e.target.value;
														const kg = stones * 6.35;
														setFieldValue('weight', kg.toFixed(2)); // Convert and update the other field
													}}
												/>
											</div>
										</div>
									}

									{(athlete && athlete.crews.length > 0 &&
										<>
											<hr/>
											<h4>{athlete.first_name}'s Current Crew(s)</h4>
											<table className="table table-striped align-middle">
												<thead>
												<tr>
													<th>Crew Number</th>
													<th>Crew Name</th>
												</tr>
												</thead>
												<tbody>
												{athlete.crews.map((crew) => (
													<tr>
														<td>{crew.crew_number}</td>
														<td>{crew.crew_name}</td>
													</tr>
												))}
												</tbody>
											</table>
											<hr/>
										</>
									)}

									{(athlete) &&
										<div className="form-group-flex mt-2">
											<label htmlFor="event_code">Checked In</label>
											{(athlete.checked_in) ? <span className="text-success"><FontAwesomeIcon
													icon={faCheckCircle}/></span> :
												<span className="text-danger"><FontAwesomeIcon
													icon={faTimesCircle}/></span>}
										</div>
									}

									<hr/>
									<h4>Athlete Bio</h4>
									<div className="form-group-flex">
										<label htmlFor="event_code">Sporting Highlights</label>
										<Field component="textarea" name="sporting_highlights"
											   className="form-control"/>
										<ErrorMessage name="sporting_highlights" component="div"
													  className="field-error"/>
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Job/Course/University</label>
										<Field component="textarea" name="job_course_university"
											   className="form-control"/>
										<ErrorMessage name="job_course_university" component="div"
													  className="field-error"/>
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Other Info</label>
										<Field component="textarea" name="other_info" className="form-control"/>
										<ErrorMessage name="other_info" component="div" className="field-error"/>
									</div>

									<hr/>

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin/>
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}

	const AthleteHistory = () => {
		const initialValues = {
			history: athlete.history.map(item => ({
				id: item.id,
				athlete_id: athlete.id,
				event_id: item.event_id,
				year: item.year,
			})),
		};

		const addHistoryRow = (values, setFieldValue) => {
			const newHistoryItem = { athlete_id: athlete.id, event_id: '', year: '' };
			const newHistory = [...values.history, newHistoryItem];
			setFieldValue('history', newHistory);
		};

		const deleteHistoryRow = (values, setFieldValue, index) => {
			const newHistory = values.filter((item, i) => i !== index);
			setFieldValue('history', newHistory);
		};

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving athlete history...", { autoClose: false });

			const data = {
				...values,
			};

			(async () => {
				const saveAthleteHistoryRes = await saveAthleteHistory(data);
				if (saveAthleteHistoryRes.data.status === 'success') {
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Athlete history saved successfully!", autoClose: 5000 });
				} else {
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				}
			})();
		};

		const deleteRow = async (row, setFieldValue, index) => {
			if (row) {
				const toastId = toast("Deleting athlete history...", { autoClose: false });
				try {
						const deleteAthleteHistoryRes = await deleteAthleteHistory(row);
						if (deleteAthleteHistoryRes.data.status === 'success') {
							const newHistory = athlete.history.filter(item => item.id !== row);
							setAthlete({ ...athlete, history: newHistory });
							toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Athlete history deleted successfully!", autoClose: 5000 });
						} else {
							toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error deleting. Please contact support.", autoClose: 5000 });
						}
					
				} catch (error) {
					console.log('error', error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error deleting. Please contact support.", autoClose: 5000 });
				}
			} else {
				deleteHistoryRow(athlete.history, setFieldValue, index);
			}
			console.log('delete', row);
		}

		return (
			<>
				<h3>{athlete.first_name}'s Rowing History</h3>

				<Formik
					initialValues={initialValues}
					validationSchema={
						Yup.object().shape({
							history: Yup.array().of(
								Yup.object().shape({
									event_id: Yup.string().required('Required'),
									year: Yup.string().matches(/^[0-9]{4}$/, { message: 'Year must be 4 digits' }).required('Required'),
								})
							),
						})
					}
					onSubmit={onSubmit}
				>
					{({ values, setFieldValue, isValid, isSubmitting }) => (
						<Form>
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th>Event</th>
										<th>Year</th>
									</tr>
								</thead>
								<tbody>
									{values.history.map((_, index) => (
										<tr key={`history-${index}`}>
											<td>
												<Field name={`history[${index}].id`} type="hidden" />
												<Field name={`history[${index}].athlete_id`} type="hidden" />
												<Field name={`history[${index}].event_id`} component={SelectField} options={eventTypeList} />
												<ErrorMessage name={`history[${index}].event_id`} component="div" className="field-error" />
											</td>
											<td>
												<Field name={`history[${index}].year`} className="form-control" />
												<ErrorMessage name={`history[${index}].year`} component="div" className="field-error" />
											</td>
											<td><button type="button" className="btn btn-delete" onClick={() => deleteRow(athlete.history?.[index]?.id, setFieldValue, index)}><FontAwesomeIcon icon={faTimes} /></button></td>
										</tr>
									))}
								</tbody>
							</table>
							<div className="row">
								<div className="col-12 col-md-6">
									<button
										type="button"
										onClick={() => addHistoryRow(values, setFieldValue)}
										disabled={isSubmitting}
										className="btn btn-secondary"
									>
										Add History
									</button>
								</div>
								<div className="col-12 col-md-6 text-end">
									<button
										type="submit"
										disabled={isSubmitting}
										className="btn btn-primary"
									>
										Save History
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const AthleteCrewHistory = () => {
		const initialValues = {
			crewhistory: athlete.crew_history.map(item => ({
				id: item.id,
				crew_name: item.crew_name,
				crew_number: item.crew_number,
				athlete_id: athlete.id,
				event_code: item.event_code,
				year: item.year,
			})),
		};

		return (
			<div className="mt-5">
				<h3>{athlete.first_name}'s Crew History</h3>
				<Formik
					initialValues={initialValues}
					validationSchema={
						Yup.object().shape({
							crewhistory: Yup.array().of(
								Yup.object().shape({
									event_id: Yup.string().required('Required'),
									year: Yup.string().matches(/^[0-9]{4}$/, { message: 'Year must be 4 digits' }).required('Required'),
								})
							),
						})
					}
				>
					{({ values, setFieldValue, isValid, isSubmitting }) => (
						<Form>
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th>Event</th>
										<th>Crew Number</th>
										<th>Crew Name</th>
										<th>Year</th>
									</tr>
								</thead>
								<tbody>
									{values.crewhistory.map((_, index) => (
										<tr key={`crewhistory-${index}`}>
											<td>
												<Field name={`crewhistory[${index}].id`} type="hidden" />
												<Field name={`crewhistory[${index}].athlete_id`} type="hidden" />
												<Field name={`crewhistory[${index}].event_code`} className="form-control" disabled />
												<ErrorMessage name={`crewhistory[${index}].event_id`} component="div" className="field-error" />
											</td>
											<td>
												<Field name={`crewhistory[${index}].crew_number`} className="form-control" disabled />
												<ErrorMessage name={`crewhistory[${index}].crew_number`} component="div" className="field-error" />
											</td>
											<td>
												<Field name={`crewhistory[${index}].crew_name`} className="form-control" disabled />
												<ErrorMessage name={`crewhistory[${index}].crew_name`} component="div" className="field-error" />
											</td>
											<td>
												<Field name={`crewhistory[${index}].year`} className="form-control" disabled />
												<ErrorMessage name={`crewhistory[${index}].year`} component="div" className="field-error" />
											</td>
											{/* <td><button type="button" className="btn btn-delete" onClick={() => deleteRow(athlete.history?.[index]?.id, setFieldValue, index)}><FontAwesomeIcon icon={faTimes} /></button></td> */}
										</tr>
									))}
								</tbody>
							</table>
							<div className="row">
								<div className="col-12 col-md-6">
									{/* <button
										type="button"
										onClick={() => addHistoryRow(values, setFieldValue)}
										disabled={isSubmitting}
										className="btn btn-secondary"
									>
										Add History
									</button> */}
								</div>
								<div className="col-12 col-md-6 text-end">
									{/* <button
										type="submit"
										disabled={isSubmitting}
										className="btn btn-primary"
									>
										Save History
									</button> */}
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		);
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/athletes"><FontAwesomeIcon icon={faArrowLeft} /> Back to Athletes</Link></div>
				<div className="col-12 col-md-6">
					{(athlete) ? <h1>Edit Athlete</h1> : <h1>Add Athlete</h1>}
				</div>

				<div className="col-12 form-group">
					{<AthleteForm />}
					{(athlete) && <AthleteHistory />}
					{(athlete) && <AthleteCrewHistory />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(AthleteSingle);
