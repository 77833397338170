import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const Table = ({ data = [], visibleColumns = [], hideTitles = [], linkColumn, linkLocation, editable, handleDelete, onSort, sortedColumn, sortDirection, onSelectedRowsChange = null, resetSelected = false }) => {
	// State to manage selected rows
	const [selectedRows, setSelectedRows] = useState([]);
	// State to manage the master checkbox
	const [selectAll, setSelectAll] = useState(false);

	// When handling individual row selection
	const handleRowCheckboxChange = (rowId) => {
		const newSelectedRows = selectedRows.includes(rowId)
			? selectedRows.filter(id => id !== rowId)
			: [...selectedRows, rowId];

		setSelectedRows(newSelectedRows);
		// Call the callback function passed from the parent
		onSelectedRowsChange(newSelectedRows);
	};

	// Inside useEffect for select all
	useEffect(() => {
		// if onSelectedRowsChange is a function
		if (onSelectedRowsChange) {
			if (selectAll) {
				const allRowIds = data.map(item => item.id);
				setSelectedRows(allRowIds);
				onSelectedRowsChange(allRowIds); // Notify parent
			} else {
				setSelectedRows([]);
				onSelectedRowsChange([]); // Notify parent
			}
		}

	}, [selectAll]);

	// Reset selected rows
	useEffect(() => {
		if (resetSelected) {
			setSelectedRows([]);
			setSelectAll(false);
		}
	}, [resetSelected]);

	const formatTitle = (title, type) => {
		if (hideTitles.includes(title)) {
			return null;
		}

		if (type === 'checkbox') {
			return (
				<input
					type="checkbox"
					checked={selectAll}
					onChange={(e) => setSelectAll(e.target.checked)}
				/>
			);
		}

		return title.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	};

	const columns = visibleColumns.length > 0 ? visibleColumns : data[0] ? Object.keys(data[0]) : [];

	return (
		<table className="table table-striped">
			<thead>
				<tr>
					{columns.map((column, index) => (
						!hideTitles.includes(column) && (
							column.sort ?
								<th key={index} onClick={() => onSort(column.sort)}>
									{formatTitle(column.name, column.type)} {sortedColumn === column.sort ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
								</th> :
								<th key={index}>
									{formatTitle(column.name, column.type)}
								</th>
						)
					))}
					{editable && <th width="20px"></th>}
					{handleDelete && <th width="20px"></th>}
				</tr>
			</thead>
			<tbody>
				{data.map((item, rowIndex) => (
					<tr key={rowIndex}>
						{columns.map((column, colIndex) => (
							<td key={colIndex}>
								{column.type === 'checkbox' ?
									<input
										type="checkbox"
										checked={selectedRows.includes(item.id)}
										onChange={() => handleRowCheckboxChange(item.id)}
									/>
									: column.name === linkColumn ?
										<Link to={`${linkLocation}/${item.id}`}>{item[column.name]}</Link>
										:
										item[column.name]
								}
							</td>
						))}
						{editable && <td><Link to={`${linkLocation}/${item.id}`} className="btn btn-info"><FontAwesomeIcon icon={faEdit} /></Link></td>}
						{handleDelete && <td><button onClick={() => handleDelete(item.id)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></td>}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default Table;
