import instance from './config';

const getDashboard = () => {
	return instance.get('/page/dashboard');
};

const getSettings = () => {
	return instance.get('/admin/settings');
};

const getPage = (slug) => {
	return instance.get('/page-by-slug', { params: { slug } });
};

const globalReset = () => {
	return instance.get('/reset');
};

const doBackup = (values) => {
	return instance.get('/admin/backup', { params: values });
};

const listBackups = () => {
	return instance.get('/admin/list-backups');
};

const restoreBackup = (backup) => {
	return instance.post('/admin/restore-backups', backup);
};

const bulkUpdateStatus = () => {
	return instance.post(`/admin/status/entries`);
};

const getEmailTemplates = () => {
	return instance.get('/admin/email-templates');
}

const saveEmailTemplate = (values) => {
	return instance.post('/admin/email-templates', values);
}

const sendCrewCheckInReminder = () => {
	return instance.get('/admin/crew-check-in-reminder');
}

const archiveDraftEntries = () => {
	return instance.post('/admin/archive-entries');
}

export {
	getDashboard,
	getSettings,
	globalReset,
	getPage,
	doBackup,
	listBackups,
	restoreBackup,
	bulkUpdateStatus,
	getEmailTemplates,
	saveEmailTemplate,
	sendCrewCheckInReminder,
	archiveDraftEntries
};
