import instance from './config';

const createEntry = (event_id) => {
	return instance.post(`/create-entry`, { event_id: event_id });
};

// Public routes
const getMyEntries = (status, page = 1, sortDir = 'asc', sortCol = 'created_at') => {
	// if status is an array, create a query string
	if (Array.isArray(status)) {
		const statusQuery = status.join('&status[]=');
		return instance.get(`/entries?status[]=${statusQuery}&page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
	} else {
		return instance.get(`/entries?status=${status}&page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
	}
};

const getEntries = (filters, page = 1, sortDir = 'asc', sortCol = 'created_at') => {
	// if status is an array, create a query string
	let filterQuery = "";

	// console.log('filters', filters);

	Object.entries(filters).map((filter, key) => {
		// console.log('filter', filter[1]);
		if (filter[1] && Array.isArray(filter[1])) {
			filter[1].forEach((filterValue) => {
				filterQuery += `${filter[0]}[]=${filterValue.value}&`;
			});
		} else if (filter[1] && filter[1].value) {
			filterQuery += `${filter[0]}[]=${filter[1].value}&`;
		} else if (filter[1]) {
			filterQuery += `${filter[0]}=${filter[1]}&`;
		}
	});

	// remove the last &
	// filterQuery = filterQuery.slice(0, -1);
	return instance.get(`/admin/entries?${filterQuery}page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getEntry = (id) => {
	return instance.get(`/entry/${id}`);
};

const saveEntry = (id, data) => {
	return instance.put(`/entry/${id}`, data);
};

// Admin routes
const saveAdminEntries = (id, data) => {
	return instance.put(`/admin/entries/${id}`, data);
};

const printAdminEntry = (id) => {
	return instance.get(`/admin/entries/${id}/print`);
};

const getAdminEntry = (id) => {
	return instance.get(`/admin/entries/${id}`);
};

const getAdminEntries = () => {
	return instance.get(`/admin/entries`);
};

const renameAdminEntry = (id) => {
	return instance.get(`/admin/entry-names/${id}`);
};

const withdrawEntry = (id, data) => {
	return instance.put(`/withdraw/${id}`, data);
};

// TODO: Create route for this
const getMyPaidEntries = () => {
	return instance.get('/paid/entries');
};

const deleteMyEntry = (id) => {
	return instance.delete(`/entry/${id}`);
}

const getCoaches = () => {
	return instance.get('/coaches');
};

export {
	createEntry,
	getMyEntries,
	getEntries,
	getEntry,
	saveEntry,
	deleteMyEntry,
	withdrawEntry,
	getAdminEntry,
	getAdminEntries,
	getMyPaidEntries,
	saveAdminEntries,
	printAdminEntry,
	renameAdminEntry,
	getCoaches
}
