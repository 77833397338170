import {useEffect, useState, useRef} from 'react';
import {withRouter} from '../../../common/with-router';
import {Link} from 'react-router-dom';
import Loading from '../../../components/global/loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';
import {getOrder, updateOrder} from '../../../api/orders';
import {emailSend} from '../../../api/triggers';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import OrderItems from "../../../components/orders/orderItems";
import moment from "moment";

const OrderSingle = (props) => {
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState(null);

	const getInitialData = async (id) => {
		try {
			const orderResponse = await getOrder(id);
			if (orderResponse.data.status === 'success') {
				setOrder(orderResponse.data.order);
				console.log(orderResponse.data.order)
				setLoading(false);
			}
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				setLoading(true);
				getInitialData(id);
			}
		}
	}, []);

	let initialValues = {
		status: order?.status || 0,
	}

	const validationSchema = Yup.object({
		status: Yup.number()
			.oneOf([0, 1, 2, 3], 'Invalid status value')
			.required('Status is required'),
	});

	const onSubmit = async (values) => {
		const toastId = toast("Updating order...", {autoClose: false});

		try {
			const response = await updateOrder(order.id, values);
			if (response.data.status === 'success') {
				toast.update(toastId, {type: toast.TYPE.SUCCESS, render: "Order updated", autoClose: 5000});
			} else {
				toast.update(toastId, {type: toast.TYPE.ERROR, render: "Error updating order", autoClose: 5000});
			}
		} catch (error) {
			console.log('errr', error);
			toast.update(toastId, {type: toast.TYPE.ERROR, render: "Error updating order", autoClose: 5000});
		}
	}

	const OrderData = () => {
		if (order) {
			return (
				<div>
					<div className="row">
						<div className="col-12 col-md-6">
							<Formik
								enableReinitialize={true}
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
							>
								{({values, dirty}) => (
									<Form>
										<h3>Order Summary</h3>
										<table className="table">
											<tbody>
											<tr>
												<td><strong>Order ID</strong></td>
												<td>{order.orderId}</td>
											</tr>
											<tr>
												<td><strong>Customer Name</strong></td>
												<td>{order.user.first_name} {order.user.last_name} (<Link
													to={`/admin/users/edit/${order.user.id}`}>{order.user.email}</Link>)
												</td>
											</tr>
											{(order.discount_code) &&
												<tr>
													<td><strong>Coupon Used</strong></td>
													<td>{order.discount_code}</td>
												</tr>
											}
											<tr>
												<td><strong>Status</strong></td>
												<td>
													<Field as="select" name="status" className="form-select">
														<option value="0">Pending
															Payment
														</option>
														<option value="1">Complete
														</option>
														<option value="2">Refunded
														</option>
														<option value="3">On-hold</option>
													</Field>
												</td>
											</tr>
											</tbody>
										</table>
										<div className="form-group form-buttons">
											<button className="btn btn-primary btn-block"
													type="submit"
													disabled={!dirty}
											>
												<span>Update Order</span>
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>

						<div className="col-12 col-md-6">
							<h3>Opayo Data</h3>
							<table className="table">
								<tbody>
								<tr>
									<td><strong>Order Total</strong></td>
									<td>£{order.amount.toFixed(2)}</td>
								</tr>
								<tr>
									<td><strong>TxCode</strong></td>
									<td>{order.VendorTxCode}</td>
								</tr>
								<tr>
									<td><strong>Opayo Auth No</strong></td>
									<td>{order.TxAuthNo}</td>
								</tr>
								<tr>
									<td><strong>Status Details</strong></td>
									<td>{order.StatusDetail}</td>
								</tr>
								<tr>
									<td><strong>Transaction Date:</strong></td>
									<td>{order.transaction_date ? moment(order.transaction_date).format('Do MMMM YYYY [at] H:mma') : ''}</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<OrderItems order={order}/>
				</div>
			)
		}
	}

	if (loading) {
		return <Loading/>;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/orders"><FontAwesomeIcon icon={faArrowLeft}/> Back to
					Orders</Link></div>
				<div className="col-12 col-md-6">
					<h1>Order: {order.id}</h1>
				</div>

				<div className="col-12 form-group">
					{<OrderData/>}
				</div>
			</div>
		</div>
	);
}

export default withRouter(OrderSingle);
